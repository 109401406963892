import { inject, Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { PermissionRole, PermissionValues, User } from 'app/core/models';
import { SetUser, SetUsers } from './actions';
import { FeatureFlag, NewPermission, Permission, ProvisionalPermissionsMap } from 'app/core/enums';
import { AccountState } from '@state/account/state';

export interface UserStateModel {
  user: User;
  users: User[];
}

@State<UserStateModel>({
  name: new StateToken<UserStateModel>('userState'),
  defaults: { user: null, users: null },
})
@Injectable()
export class UserState {
  private readonly store = inject(Store);

  @Selector()
  static getUser(state: UserStateModel): User {
    return state.user;
  }

  @Selector()
  static getUsers(state: UserStateModel): User[] {
    return state.users;
  }

  @Selector()
  static getUserPermissionRoles(state: UserStateModel): PermissionRole[] {
    return state.user?.roles[0]?.permissions_roles.filter(
      ({ permission_value }) => PermissionValues.truthy[permission_value],
    );
  }

  @Selector([UserState.getUserPermissionRoles, AccountState.isFeatureEnabled(FeatureFlag.NEW_PERMISSIONS)])
  static getUserPermissionNames(
    state: UserStateModel,
    roles: PermissionRole[],
    newPermissionsFeatureEnabled: boolean,
  ): (Permission | NewPermission)[] {
    const result = roles?.map(({ permission }) => <Permission | NewPermission>permission.name) ?? [];

    if (newPermissionsFeatureEnabled) {
      // those two permissions don't exist in the new permissions, but they are still being used in the code
      // so we need to add them to the list of permissions.
      // This shouldn't be needed once we remove the old permissions.
      result.push(Permission.MAPS_ACCESS_ALL, Permission.MAPS_EDIT_LAYOUT);
    }

    console.log('>>>> User Permissions: ', result);

    return result;
  }

  static hasPermission(permission: Permission | NewPermission, onlyOne = false) {
    return createSelector(
      [AccountState.isFeatureEnabled(FeatureFlag.NEW_PERMISSIONS), UserState.getUserPermissionNames],
      (newPermissionsFeatureEnabled, userPermissionNames: (Permission | NewPermission)[]): boolean => {
        if (!newPermissionsFeatureEnabled && NewPermission[permission]) {
          return true;
        }

        const hasPermission = ProvisionalPermissionsMap.permissionsMap[permission]?.some(
          (permissionInMap: Permission) => userPermissionNames.includes(permissionInMap),
        );

        if (hasPermission && onlyOne) {
          return userPermissionNames.length === 1;
        }

        return hasPermission;
      },
    );
  }

  @Action(SetUser)
  setUser(context: StateContext<UserStateModel>, { user }: { user: User }) {
    const state = context.getState();
    context.setState({ ...state, user });
  }

  @Action(SetUsers)
  setUsers(context: StateContext<UserStateModel>, { users }: { users: User[] }) {
    const state = context.getState();
    context.setState({ ...state, users });
  }
}
