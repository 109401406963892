<section class="ts-snackbar">
  <div class="ts-snackbar__content">
    <ng-container *ngIf="data.closeButton">
      <ts-button
        type="icon"
        color="primary"
        icon="close"
        class="ts-snackbar__close-btn cursor-pointer"
        (click)="onDismissClick()"
      ></ts-button>
    </ng-container>
    <ng-container *ngIf="iconType">
      <div class="ts-snackbar__icon">
        <ts-icon [icon]="iconType"></ts-icon>
      </div>
    </ng-container>
    <div class="ts-snackbar__message">
      {{ data.message }}
    </div>
    <ng-container *ngIf="data.actionText">
      <ng-container *ngIf="data.actionText === 'confirm-changes'; else DefaultAction">
        <div class="ts-snackbar__actions flex items-center">
          <button mat-stroked-button (click)="onResetClick()">
            <ts-icon icon="restart_alt" class="mr-2"></ts-icon>
            Reset
          </button>
          @if (disableSaving) {
          <ts-button type="raised" color="primary" class="ml-2" (click)="onSaveClick()">
            <ts-icon icon="save" class="mr-2"></ts-icon>
            Save
          </ts-button>
          }
        </div>
      </ng-container>
    </ng-container>
  </div>
</section>

<ng-template #DefaultAction>
  <div class="ts-snackbar__actions">
    <button mat-stroked-button (click)="onDismissClick()">
      {{ data.actionText }}
    </button>
  </div>
</ng-template>
