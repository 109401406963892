import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'app/core/services/auth.service';
import { FeatureFlag, Module, NewPermission, Permission } from '../enums';
import { unauthorizedHandler } from './unauthorized-handler';
import { AccountState } from '@state/account/state';
import { Store } from '@ngxs/store';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (state.url.startsWith('/unauthorized')) {
      return true;
    } else {
      const module = route.data?.module as Module;
      const isNewPermissionsOn = this.store.selectSnapshot(AccountState.isFeatureEnabled(FeatureFlag.NEW_PERMISSIONS));

      if (isNewPermissionsOn) {
        if (module === Module.AGGREGATED_TASKS) {
          const hasAny = this.authService.hasAnyPermissionInModule(module);

          if (!hasAny) {
            unauthorizedHandler(this.authService, this.router);
          }

          return hasAny;
        }
      }

      const permission = route.data?.permission as Permission;

      if (permission) {
        const hasPermission = this.authService.hasPermission(permission);

        if (!hasPermission) {
          unauthorizedHandler(this.authService, this.router);
        }

        return hasPermission;
      }

      if (module) {
        let hasAny = this.authService.hasAnyPermissionInModule(module);
        
        if (!hasAny) {
          unauthorizedHandler(this.authService, this.router);
        }

        return hasAny;
      }
    }

    return true;
  }
}
