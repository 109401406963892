import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import {
  TaskList,
  Task,
  TaskListTemplate,
  TaskStatus,
  TaskResult,
  TaskListResult,
  Todo,
} from 'app/shared/modules/tasks/tasks.types';
import {
  TaskRole,
  TaskRoleGetResponse,
  TaskRoleMapping,
  TaskRoleMappingCreate,
  TaskRoleMappingCreateResponse,
  TaskRoleMappingGetResponse,
} from '../../../../modules/settings/task-roles/task-roles.types';
import { TaskExportUtilsService } from '@shared/modules/tasks/services/task-export-utils.service';
import { RelatedToType } from 'app/shared/enums';
import { Approval, ApprovalStatus } from '../../approvals/approvals.types';
import { Project } from 'app/modules/projects/projects.types';
import { Store } from '@ngxs/store';
import { AccountState } from '@state/account/state';

@Injectable({
  providedIn: 'root',
})
export class TasksTabService {
  private _taskList: BehaviorSubject<TaskList | null>;
  private _taskLists: BehaviorSubject<TaskList[] | null>;
  private _taskListTemplates: BehaviorSubject<TaskListTemplate[] | null>;
  private _taskListTemplate: BehaviorSubject<TaskListTemplate | null>;
  private _tasks: BehaviorSubject<Task[] | null>;
  private _task: BehaviorSubject<Task | null>;
  private _taskRolesMappings: BehaviorSubject<TaskRoleMapping[] | null>;
  private _taskRoles: BehaviorSubject<TaskRole[] | null>;
  private _moduleId: string;
  private _baseModuleId: string;
  private _moduleBaseUrl: string;
  private _isGlobalTasks: boolean;
  private _accountId: string;
  private _drawerToggle: Subject<boolean> = new Subject();
  // [SC-24187] Used as a component communication flag between details and list to listen for
  // task creation/update and trigger filter method to update the list.
  private _updateListFilter: BehaviorSubject<boolean>;

  workflowStatuses: TaskStatus[] = [
    {
      key: 'n_a',
      label: 'N/A',
      color: 'gray',
      icon: 'more_horiz',
    },
    {
      key: 'not_started',
      label: 'Not Started',
      color: 'gray',
      icon: 'more_horiz',
    },
    {
      key: 'needs_review',
      label: 'Needs Review',
      color: 'red',
      icon: 'error_outline',
    },
    {
      key: 'in_progress',
      label: 'In Progress',
      color: 'blue',
      icon: 'more_horiz',
    },
    {
      key: 'in_approval',
      label: 'In Approval',
      color: 'yellow-300',
      icon: 'more_horiz',
    },
    {
      key: 'complete',
      label: 'Complete',
      color: 'green',
      icon: 'done',
    },
  ];

  constructor(
    private _httpClient: HttpClient,
    private store: Store,
    private taskExportUtilsService: TaskExportUtilsService,
  ) {
    // Set the private defaults
    this._taskList = new BehaviorSubject(null);
    this._taskLists = new BehaviorSubject(null);
    this._taskListTemplates = new BehaviorSubject(null);
    this._taskListTemplate = new BehaviorSubject(null);
    this._tasks = new BehaviorSubject(null);
    this._task = new BehaviorSubject(null);
    this._moduleId = '';
    this._baseModuleId = '';
    this._moduleBaseUrl = '';
    this._isGlobalTasks = false;
    this._taskRolesMappings = new BehaviorSubject(null);
    this._taskRoles = new BehaviorSubject(null);
    this._updateListFilter = new BehaviorSubject<boolean>(false);

    const account = this.store.selectSnapshot(AccountState.getAccount);
    this._accountId = account.id;
  }

  get drawerToggle$(): Observable<boolean> {
    return this._drawerToggle.asObservable();
  }

  set drawerToggle(val: boolean) {
    this._drawerToggle.next(val);
  }

  get updateListFilter$(): BehaviorSubject<boolean> {
    return this._updateListFilter;
  }

  set updateListFilter(val: boolean) {
    this._updateListFilter.next(val);
  }

  get taskList$(): Observable<TaskList> {
    return this._taskList.asObservable();
  }

  set taskList(val: TaskList) {
    this._taskList.next(val);
  }

  get taskLists$(): Observable<TaskList[]> {
    return this._taskLists.asObservable();
  }

  get tasks$(): Observable<Task[]> {
    return this._tasks.asObservable();
  }

  set tasks(tasks: Task[]) {
    this._tasks.next(tasks);
  }

  get task$(): Observable<Task> {
    return this._task.asObservable();
  }

  set task(task: Task) {
    this._task.next(task);
  }

  get taskRolesMappings$(): Observable<TaskRoleMapping[]> {
    return this._taskRolesMappings.asObservable();
  }

  get taskRoles$(): Observable<TaskRole[]> {
    return this._taskRoles.asObservable();
  }

  get taskListTemplates$(): Observable<TaskListTemplate[]> {
    return this._taskListTemplates.asObservable();
  }

  get taskListTemplate$(): Observable<TaskListTemplate> {
    return this._taskListTemplate.asObservable();
  }

  /**
   * @deprecated TaskService now has a instance of the AccountState.
   * Use the store to set the account and the accountId will be set automatically.
   */
  set accountId(val: string) {
    this._accountId = val;
  }

  /**
   * @deprecated Use the this.store.dispatch(new TaskActions.SetRelatedTo(route.data.taskModuleRelatedTo, +relatedToId)) instead;
   */
  set moduleId(val: string) {
    this._moduleId = val;
    this._isGlobalTasks = false;
  }

  /**
   * @deprecated Use the this.store.dispatch(new TaskActions.SetRelatedTo(route.data.taskModuleRelatedTo, +relatedToId)) instead;
   */
  get moduleId() {
    return this._moduleId;
  }

  /**
   * @deprecated Use the this.store.dispatch(new TaskActions.SetRelatedTo(route.data.taskModuleRelatedTo, +relatedToId)) instead;
   */
  set baseModuleId(val: string) {
    this._baseModuleId = val;
    this._isGlobalTasks = false;
  }

  /**
   * @deprecated Use the this.store.dispatch(new TaskActions.SetRelatedTo(route.data.taskModuleRelatedTo, +relatedToId)) instead;
   */
  get baseModuleId() {
    return this._baseModuleId;
  }

  get apiBaseUrl() {
    if (this.moduleBaseUrl?.includes('custom_objects')) {
      return `${this.moduleBaseUrl}/${this.moduleId}`;
    } else {
      return this.store.selectSnapshot((state) => state.taskState.apiBaseUrl);
    }
  }

  /**
   * @deprecated Use the this.store.dispatch(new TaskActions.SetRelatedTo(route.data.taskModuleRelatedTo, +relatedToId)) instead;
   */
  set moduleBaseUrl(val: string) {
    this._moduleBaseUrl = val;
    this._isGlobalTasks = false;
  }

  /**
   * @deprecated Use the this.store.dispatch(new TaskActions.SetRelatedTo(route.data.taskModuleRelatedTo, +relatedToId)) instead;
   */
  get moduleBaseUrl() {
    return this._moduleBaseUrl;
  }

  /**
   * @deprecated Don't need to set isGlobalTasks anymore.
   * If you need to check if you are in global tasks or not, check if the tasksState.relatedToType is equal to Account.
   */
  set isGlobalTasks(val: boolean) {
    this._isGlobalTasks = val;
  }

  /**
   * @deprecated Don't need to set isGlobalTasks anymore.
   * If you need to check if you are in global tasks or not, check if the tasksState.relatedToType is equal to Account.
   */
  get isGlobalTasks() {
    return this._isGlobalTasks;
  }

  getTaskLists(): Observable<TaskListResult> {
    const url = `${this.apiBaseUrl}/task_lists?account_id=${this._accountId}`;
    return this._httpClient.get<TaskListResult>(url).pipe(
      tap(({ task_lists }) => {
        this._taskLists.next(task_lists);
      }),
    );
  }

  getTaskListById(taskListId: string, templateId?: number, include_tasks = false): Observable<TaskList> {
    let url = `${this.apiBaseUrl}/task_lists/${taskListId}?account_id=${this._accountId}`;
    if (templateId) {
      url = `${url}&task_list_template_id=${templateId}`;
    }

    include_tasks = include_tasks && (this.moduleBaseUrl === 'projects' || this.apiBaseUrl.includes('projects')); // Only include tasks for projects
    url = include_tasks ? `${url}&include_tasks=true` : url;

    return this._httpClient.get<any>(url).pipe(
      tap((response) => {
        this._taskList.next(response.task_list);
        if (include_tasks) {
          const tasks = this.deepTaskListProjectTransform(response.task_list, response?.project);
          response.task_list.tasks = tasks;
          this._tasks.next(tasks);
        }
        return response.task_list;
      }),
    );
  }

  getTaskRolesById(typeName?: string): Observable<TaskRole[]> {
    return this._httpClient.get<TaskRoleGetResponse>(`accounts/${this._accountId}/task_roles`).pipe(
      map((response: TaskRoleGetResponse) => {
        const taskRoles = typeName
          ? response.task_roles.filter((tr) => tr.applicable_type === typeName)
          : response.task_roles;
        this._taskRoles.next(taskRoles);
        return taskRoles;
      }),
    );
  }

  getTaskRolesMappingsById(typeName: string, typeId: number): Observable<TaskRoleMapping[]> {
    let url = `accounts/${this._accountId}/task_role_mappings?related_to_type=${typeName}&related_to_id=${typeId}`;
    return this._httpClient.get<TaskRoleMappingGetResponse>(url).pipe(
      map((response) => {
        this._taskRolesMappings.next(response.task_role_mappings);
        return response.task_role_mappings;
      }),
    );
  }

  createTaskRolesMapping(trMapping: TaskRoleMappingCreate): Observable<TaskRoleMapping> {
    let url = `accounts/${this._accountId}/task_role_mappings`;
    return this._httpClient.post<TaskRoleMappingCreateResponse>(url, { task_role_mapping: trMapping }).pipe(
      map((response) => {
        return response.task_role_mapping;
      }),
    );
  }

  updateTaskRolesMapping(mappingId: number, userId: number): Observable<TaskRoleMapping> {
    let url = `accounts/${this._accountId}/task_role_mappings/${mappingId}`;
    return this._httpClient.patch<TaskRoleMappingCreateResponse>(url, { user_id: userId }).pipe(
      map((response) => {
        return response.task_role_mapping;
      }),
    );
  }

  deleteTaskRolesMappingById(mappingId: number): Observable<void> {
    let url = `accounts/${this._accountId}/task_role_mappings/${mappingId}`;
    return this._httpClient.delete<void>(url);
  }

  getTaskById(taskListId: string, templateId?: number): Observable<Task> {
    const url = (taskList: TaskList) => {
      if (this.isGlobalTasks) {
        return templateId
          ? `${this.apiBaseUrl}/task_lists/${taskList.id}/tasks/${taskListId}?task_list_template_id=${templateId}`
          : `${this.apiBaseUrl}/task_lists/${taskList.id}/tasks/${taskListId}`;
      } else {
        return `${this.apiBaseUrl}/task_lists/${taskList.id}/tasks/${taskListId}?account_id=${this._accountId}`;
      }
    };

    return this.taskList$.pipe(
      take(1),
      switchMap((taskList) => {
        return this._httpClient.get<any>(url(taskList)).pipe(
          tap((response) => {
            this._task.next(response.task);
            return response.task;
          }),
        );
      }),
    );
  }

  getTasks(
    taskListId: number,
    templateId?: number,
    filters: any = {},
    format: string = '',
    updateTasks: boolean = true,
    select: string = null,
  ): Observable<Task[]> {
    let url: string;
    if (this.isGlobalTasks) {
      url = templateId
        ? `${this.apiBaseUrl}/task_lists/${taskListId}/tasks?task_list_template_id=${templateId}`
        : `${this.apiBaseUrl}/task_lists/${taskListId}/tasks`;
    } else {
      url = `${this.apiBaseUrl}/task_lists/${taskListId}/tasks`;
    }

    const {
      workflowStateIn = [],
      assignedUserIdIn = [],
      dueDateGteq = '',
      dueDateLteq = '',
      taskTypeIn = [],
    } = filters;

    if (taskTypeIn.length > 0) {
      taskTypeIn.push('section');
    }

    const params = {
      workflow_state_in: workflowStateIn.join(','),
      assigned_user_id_in: assignedUserIdIn.join(','),
      due_date_gteq: dueDateGteq,
      due_date_lteq: dueDateLteq,
      task_type_in: taskTypeIn.join(','),
      format: format,
    };

    if (select) {
      params['select'] = select;
    }

    return this._httpClient.get<any>(url, { params }).pipe(
      map((response) => {
        if (format && response && (format == 'csv' || format == 'pdf')) {
          if (response?.data?.message) {
            this.taskExportUtilsService.onExportResultWithMessage(response.data.message);
          }

          return response.data;
        }
        let tasks = response.tasks;
        if (updateTasks) {
          this._tasks.next(tasks);
        }

        return tasks;
      }),
    );
  }

  createTaskList(taskList: TaskList): Observable<TaskList> {
    const url = this.isGlobalTasks
      ? `${this.apiBaseUrl}/task_lists`
      : `${this.apiBaseUrl}/task_lists?account_id=${this._accountId}`;

    return this.taskLists$.pipe(
      take(1),
      switchMap((taskLists) =>
        this._httpClient
          .post<any>(url, {
            task_list: taskList,
          })
          .pipe(
            map((reponse) => {
              // Update the contacts with the new contact
              this._taskLists.next([reponse.task_list, ...taskLists]);

              // Return the new contact
              return reponse.task_list;
            }),
          ),
      ),
    );
  }

  updateTaskList(taskList: TaskList, templateId?: number, updateSubject = true): Observable<TaskList> {
    const url = (taskList: TaskList) => {
      if (this.isGlobalTasks) {
        return templateId
          ? `${this.apiBaseUrl}/task_lists/${taskList.id}?task_list_template_id=${templateId}`
          : `${this.apiBaseUrl}/task_lists/${taskList.id}`;
      } else {
        return `${this.apiBaseUrl}/task_lists/${taskList.id}?account_id=${this._accountId}`;
      }
    };

    return this.taskLists$.pipe(
      take(1),
      switchMap((taskLists) =>
        this._httpClient
          .put<any>(url(taskList), {
            task_list: taskList,
          })
          .pipe(
            map((reponse) => {
              const updatedTaskLists = taskLists.map((taskList) => {
                if (taskList.id === reponse.task_list.id) {
                  return reponse.task_list;
                }
                return taskList;
              });

              if (updateSubject) {
                this._taskLists.next(updatedTaskLists);
                this._taskList.next(reponse.task_list);
              }

              return reponse.task_list;
            }),
          ),
      ),
    );
  }

  deleteTaskList(id: number): Observable<any> {
    const url = this.isGlobalTasks
      ? `${this.apiBaseUrl}/task_lists/${id}`
      : `${this.apiBaseUrl}/task_lists/${id}?account_id=${this._accountId}`;

    return this.taskLists$.pipe(
      take(1),
      switchMap((taskLists) =>
        this._httpClient.delete<any>(url).pipe(
          map((reponse) => {
            // Find the index of the deleted label within the labels
            const index = taskLists.findIndex((item) => item.id === id);

            // Delete the label
            taskLists.splice(index, 1);

            // Update the labels
            this._taskLists.next(taskLists);

            // Return the deleted status
            return reponse.task_list;
          }),
        ),
      ),
    );
  }

  loadTasks(): Observable<Task[]> {
    return this._httpClient.get<any>(`${this.apiBaseUrl}/tasks?account_id=${this._accountId}`).pipe(
      map((response) => {
        let tasks = response.tasks;
        this._tasks.next(tasks);
        return tasks;
      }),
    );
  }

  createTask(
    taskListId: number,
    task: Task,
    pos?: number,
    isSubtask?: boolean,
    updateSubject = true,
    append = true,
  ): Observable<Task> {
    task.workflow_state = task.workflow_state ?? 'not_started';

    const url = (taskListId: number) => {
      if (this.isGlobalTasks) {
        return `${this.apiBaseUrl}/task_lists/${taskListId}/tasks`;
      } else {
        return `${this.apiBaseUrl}/task_lists/${taskListId}/tasks?account_id=${this._accountId}`;
      }
    };
    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient
          .post<any>(url(taskListId), {
            task,
          })
          .pipe(
            map((response) => {
              let newTask = response.task;
              if (updateSubject) {
                if (!isSubtask) {
                  if (pos && tasks) {
                    tasks.splice(pos, 0, newTask);
                  } else if (tasks) {
                    if (append) {
                      tasks = [...tasks, newTask];
                    }
                  } else {
                    tasks = [newTask];
                  }
                  this._tasks.next(tasks);
                }
              }
              return newTask;
            }),
          ),
      ),
    );
  }

  updateTask(
    taskListId: number,
    templateId: number,
    task: Task,
    isSubtask?: boolean,
    updateSubject = true,
  ): Observable<Task> {
    const url = (taskListId: number, task: Task) => {
      if (this.isGlobalTasks) {
        return templateId
          ? `${this.apiBaseUrl}/task_lists/${taskListId}/tasks/${task.id}?task_list_template_id=${templateId}`
          : `${this.apiBaseUrl}/task_lists/${taskListId}/tasks/${task.id}`;
      } else {
        return `${this.apiBaseUrl}/task_lists/${taskListId}/tasks/${task.id}?account_id=${this._accountId}`;
      }
    };

    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient.put<any>(url(taskListId, task), { task }).pipe(
          map((response: { task: Task }) => {
            // Find the index of the updated task
            const index = tasks.findIndex((item) => item.id === task.id);
            let updatedTask = response.task;
            updatedTask.todos = updatedTask?.todos?.sort((a: Todo, b: Todo) => a.rank - b.rank);
            if (index === -1) {
              this.handleSubTaskUpdate(updatedTask, tasks);
            }

            // Update the task
            tasks[index] = updatedTask;
            // Update the tasks
            if (updateSubject) {
              this._tasks.next(tasks);
            }

            if (this._task.value?.id === updatedTask.id) {
              this._task.next(updatedTask);
            }

            return updatedTask;
          }),
        ),
      ),
    );
  }

  duplicateTask(taskListId: number, templateId: number, task: Task): Observable<Task> {
    let url = `${this.apiBaseUrl}/task_lists/${taskListId}/tasks/${task.id}/duplicate?account_id=${this._accountId}`;
    if (templateId) {
      url = `${url}&task_list_template_id=${templateId}`;
    }

    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient.post<any>(url, { task }).pipe(
          map((response: { task: Task }) => {
            // Find the index of the updated task
            const index = tasks.findIndex((item) => item.id === task.id);
            let updatedTask = response.task;

            // Update the task
            tasks[index] = updatedTask;

            return updatedTask;
          }),
        ),
      ),
    );
  }

  deleteTask(taskListId: number, templateId: number, id: number, updateSubject = true): Observable<any> {
    const url = (taskListId: number, id: number) => {
      if (this.isGlobalTasks) {
        return templateId
          ? `${this.apiBaseUrl}/task_lists/${taskListId}/tasks/${id}?task_list_template_id=${templateId}`
          : `${this.apiBaseUrl}/task_lists/${taskListId}/tasks/${id}`;
      } else {
        return `${this.apiBaseUrl}/task_lists/${taskListId}/tasks/${id}?account_id=${this._accountId}`;
      }
    };

    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient.delete<any>(url(taskListId, id)).pipe(
          map((reponse) => {
            // Find the index of the deleted label within the labels
            const index = tasks.findIndex((item) => item.id === id);

            // Delete the label
            tasks.splice(index, 1);

            // Update the labels
            if (updateSubject) {
              this._tasks.next(tasks);
            }

            return reponse.task;
          }),
        ),
      ),
    );
  }

  getTaskListTemplates(
    type?: RelatedToType.Account | RelatedToType.Property | RelatedToType.Project,
  ): Observable<TaskListTemplate[]> {
    let params = { account_id: this._accountId };
    if (type) {
      params['applicable_type'] = type;
    }

    return this._httpClient.get<{ task_list_templates: TaskListTemplate[] }>(`task_list_templates`, { params }).pipe(
      take(1),
      map(({ task_list_templates }) => {
        this._taskListTemplates.next(task_list_templates);
        return task_list_templates;
      }),
    );
  }

  removeApproverFromTaskListTemplate(
    taskTemplateId: number,
    taskId: number,
    approval: Approval,
  ): Observable<TaskListTemplate> {
    return this._httpClient
      .delete<any>(
        `task_list_templates/${taskTemplateId}/tasks/${taskId}/approvals/${approval.id}?account_id=${this._accountId}`,
      )
      .pipe(
        take(1),
        map(({ task_list_template }) => {
          this._taskListTemplate.next(task_list_template);
          return task_list_template;
        }),
      );
  }

  addApproverToTaskListTemplate(taskTemplateId: number, taskId: number, approval: Approval): Observable<Approval> {
    const payload = {
      approval: {
        assigned_user_id: approval.assigned_user.id,
        task_id: taskId,
      },
    };

    return this._httpClient
      .post<any>(
        `task_list_templates/${taskTemplateId}/tasks/${taskId}/approvals?account_id=${this._accountId}`,
        payload,
      )
      .pipe(
        take(1),
        map((response) => {
          const newApproval = response.approval;
          return newApproval;
        }),
      );
  }

  removeApproverFromTask(taskListId: number, taskId: number, approval: Approval): Observable<Approval> {
    let url = `task_lists/${taskListId}/tasks/${taskId}/approvals/${approval.id}?account_id=${this._accountId}`;
    url = `${this.apiBaseUrl}/${url}`;

    return this._httpClient.delete<any>(url).pipe(
      take(1),
      map((response) => {
        return response.approval;
      }),
    );
  }

  addApproverToTask(taskListId: number, taskId: number, approval: Approval): Observable<Approval> {
    let url = `task_lists/${taskListId}/tasks/${taskId}/approvals?account_id=${this._accountId}`;
    url = `${this.apiBaseUrl}/${url}`;

    const payload = {
      approval: {
        assigned_user_id: approval.assigned_user.id,
        task_id: taskId,
      },
    };

    return this._httpClient.post<any>(url, payload).pipe(
      take(1),
      map((response) => {
        return response.approval;
      }),
    );
  }

  notifyTaskApprover(taskListId: number, taskId: number, approval: Approval): Observable<Approval> {
    let url = `task_lists/${taskListId}/tasks/${taskId}/approvals/${approval.id}/notify?account_id=${this._accountId}`;
    url = `${this.apiBaseUrl}/${url}`;

    return this._httpClient.post<any>(url, { approval }).pipe(
      take(1),
      catchError(() => {
        // remove when BE is ready;
        const resentApproval = { ...approval };
        resentApproval.status = ApprovalStatus.NOT_YET;
        return of({ approval: resentApproval });
      }),
      map((response) => {
        return response.approval;
      }),
    );
  }

  notifyAllTaskApprovers(taskListId: number, taskId: number): Observable<TaskListTemplate> {
    let url = `task_lists/${taskListId}/tasks/${taskId}/approvals/notify?account_id=${this._accountId}`;
    url = `${this.apiBaseUrl}/${url}`;

    return this._httpClient.post<any>(url, {}).pipe(
      take(1),
      catchError(() => {
        // remove when BE is ready;
        return of({});
      }),
      map((response) => {
        return response;
      }),
    );
  }

  getTaskListTemplateById(taskTemplateId: number): Observable<TaskListTemplate> {
    return this._httpClient
      .get<{
        task_list_template: TaskListTemplate;
      }>(`task_list_templates/${taskTemplateId}?account_id=${this._accountId}`)
      .pipe(
        take(1),
        map(({ task_list_template }) => {
          this._taskListTemplate.next(task_list_template);
          return task_list_template;
        }),
      );
  }

  createTaskListTemplate(taskTemplate: TaskListTemplate): Observable<TaskListTemplate> {
    return this._httpClient
      .post<{ task_list_template: TaskListTemplate }>(`task_list_templates?account_id=${this._accountId}`, {
        task_list_template: taskTemplate,
      })
      .pipe(
        take(1),
        map(({ task_list_template }) => task_list_template),
      );
  }

  updateTaskListTemplate(taskTemplate: TaskListTemplate): Observable<TaskListTemplate> {
    return this._httpClient
      .put<{ task_list_template: TaskListTemplate }>(
        `task_list_templates/${taskTemplate.id}?account_id=${this._accountId}`,
        {
          task_list_template: taskTemplate,
        },
      )
      .pipe(
        take(1),
        map(({ task_list_template }) => task_list_template),
      );
  }

  deleteTaskListTemplate(taskTemplateId: number): Observable<TaskListTemplate> {
    return this.taskListTemplates$.pipe(
      take(1),
      switchMap((taskListTemplates) => {
        taskListTemplates = taskListTemplates || [];
        return this._httpClient
          .delete<TaskListTemplate>(`task_list_templates/${taskTemplateId}?account_id=${this._accountId}`)
          .pipe(
            map((taskTemplate) => {
              const index = taskListTemplates.findIndex((item) => item.id === taskTemplateId);
              if (index === -1) {
                return null;
              }
              taskListTemplates.splice(index, 1);
              this._taskListTemplates.next(taskListTemplates);
              return taskTemplate;
            }),
            catchError(() => {
              return of(null);
            }),
          );
      }),
    );
  }

  updateTasksOrders(taskList: TaskList, tasks: Task[], templateId?: number): Observable<Task[]> {
    const url = (taskList: TaskList) => {
      if (this.isGlobalTasks) {
        return templateId
          ? `${this.apiBaseUrl}/task_lists/${taskList.id}/tasks/update_order?task_list_template_id=${templateId}`
          : `${this.apiBaseUrl}/task_lists/${taskList.id}/tasks/update_order`;
      } else {
        return `${this.apiBaseUrl}/task_lists/${taskList.id}/tasks/update_order?account_id=${this._accountId}`;
      }
    };

    let rows = [];
    for (let task of tasks) {
      rows.push({ task_id: task.id, rank: task.rank, parent_id: task.parent_id });
    }

    return this._httpClient.post<any>(url(taskList), { tasks: rows }).pipe(
      map((response) => {
        return response.results;
      }),
    );
  }

  recalculateTaskDates(taskList: TaskList, templateId?: number): Observable<Task[]> {
    const url = (taskList: TaskList) => {
      if (this.isGlobalTasks) {
        return templateId
          ? `${this.apiBaseUrl}/task_lists/${taskList.id}/calculate_dates?task_list_template_id=${templateId}`
          : `${this.apiBaseUrl}/task_lists/${taskList.id}/calculate_dates`;
      } else {
        return `${this.apiBaseUrl}/task_lists/${taskList.id}/calculate_dates?account_id=${this._accountId}`;
      }
    };

    return this._httpClient.post<any>(url(taskList), {}).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getTaskStatuses(taskApprovalFlow = false): Observable<TaskStatus[]> {
    let statuses = this.workflowStatuses;
    if (!taskApprovalFlow) {
      statuses = this.workflowStatuses.filter((status) => status.key !== 'in_approval');
    }
    return of(statuses);
  }

  flattenTasks(tasks: Task[]): Task[] {
    return (
      tasks?.reduce((acc, task) => {
        if (task?.children) {
          acc?.push(task, ...this.flattenTasks(task?.children));
        } else {
          acc?.push(task);
        }
        return acc;
      }, []) ?? []
    );
  }

  orderNestedTasks(tasks: Task[]): Task[] {
    tasks.sort((a, b) => a.rank - b.rank);
    return (
      tasks?.map((task) => {
        if (task.children) {
          task.children?.sort((a, b) => a.rank - b.rank);
          this.orderNestedTasks(task.children);
        }
        return task;
      }) ?? []
    );
  }

  isSubtask(task: Task, tasks: Task[]): boolean {
    const flattenTasks = this.flattenTasks(tasks);
    const parentId = task?.parent_id;
    const parentTask = flattenTasks?.find((i) => i.id == parentId);
    if (parentTask !== undefined && parentTask?.task_type !== 'section') {
      return true;
    }
    return false;
  }

  search(filter: {
    fjson: string;
    sort: { field: string; order: string }[];
    q: string;
    include_keydates?: boolean;
    select?: string;
  }): Observable<Task[]> {
    // let's keep it just in case
    // const fjson = JSON.parse(filter.fjson || '[]');
    // fjson.push(['task_type', 'in', ['task']]);
    // const newFilter = { ...filter, fjson: JSON.stringify(fjson) };

    return this._httpClient.post(`accounts/${this._accountId}/tasks/search`, filter).pipe(
      map((response: TaskResult) =>
        // include all task_type in the search result filter.
        response.results.filter(
          ({ task_type }) =>
            ['task', 'section', 'key_date'].includes(task_type) ||
            (filter?.include_keydates ? task_type === 'key_date' : false),
        ),
      ),
    );
  }

  getDeleteConfirmationMessage(task: Task): string {
    const childrenCount = task.children.length;

    if (task.task_type === 'section') {
      switch (task.children.length) {
        case 0:
          return `You are deleting a section which will delete all tasks in the section. Do you wish to proceed?`;
        case 1:
          return `You are deleting a section that will also <strong>delete ${childrenCount} task</strong> associated with it
            Do you wish to proceed?`;
        default:
          return `You are deleting a section that will also <strong>delete ${childrenCount} tasks</strong> associated with it.
            Do you wish to proceed?`;
      }
    } else {
      switch (childrenCount) {
        case 0:
          return `Are you sure you want to delete this task?`;
        case 1:
          return `You are deleting a task that will also <strong>delete ${childrenCount} subtask</strong>.
            Do you wish to proceed?`;
        default:
          return `You are deleting a task that will also <strong>delete ${childrenCount} subtasks</strong>.
            Do you wish to proceed?`;
      }
    }
  }

  private deepTaskListProjectTransform(taskList: TaskList, project: Project): Task[] {
    return (
      taskList?.tasks?.reduce?.((acc, task) => {
        const transformedTask = this.setTaskAttributes(task, taskList, project);
        return [...acc, transformedTask];
      }, []) || []
    );
  }

  private setTaskAttributes(task: Task, taskList: TaskList, project: Project): Task {
    task.project = project;
    task.project_id = +project?.id;
    task.task_list_id = taskList.id;
    task.task_list_name = taskList.name;

    task.children = task.children?.map((child) => {
      return this.setTaskAttributes(child, taskList, project);
    });

    return task;
  }

  private handleSubTaskUpdate(updatedTask: Task, tasks: Task[]): void {
    const flattenedTasks = this.flattenTasks(tasks);
    let pathItems = [updatedTask.id];
    let pointer = updatedTask.id;
    let tempTask: Task = null;

    while ((tempTask = flattenedTasks.find(({ id }) => id === pointer))) {
      if (!tempTask.parent_id) {
        break;
      }

      pathItems.push(tempTask.parent_id);
      pointer = tempTask.parent_id;
    }

    pathItems = pathItems.filter((pathItem) => pathItem !== updatedTask.id).reverse();
    tempTask = null;

    for (const pathItem of pathItems) {
      tempTask = (tempTask?.children ?? tasks).find(({ id }) => id === pathItem);
      let index = -1;
      if ((index = tempTask.children.findIndex(({ id }) => id === updatedTask.id)) > -1) {
        tempTask.children[index] = { ...tempTask.children[index], ...updatedTask };
      }
    }
  }
}
