import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  GetEthanChats,
  GetEthanQuestions,
  AskEthan,
  UpdateEthanAnswer,
  CreateEthanChat,
  DeleteEthanChat,
  UpdateEthanQuestion,
} from './ethan.action';
import { EthanService } from './ethan.service'; // Import EthanService
import { tap } from 'rxjs/operators';
import { EthanChat, EthanMessage } from '@core/models/ethan.types';

export interface EthanStateModel {
  ethanChats: EthanChat[] | null;
  ethanQuestions: EthanMessage[] | null;
}

@State<EthanStateModel>({
  name: 'ethan',
  defaults: {
    ethanChats: [],
    ethanQuestions: [],
  },
})
@Injectable()
export class EthanState {
  constructor(private ethanService: EthanService) {} // Inject EthanService

  @Selector()
  static ethanChats(state: EthanStateModel) {
    return state.ethanChats;
  }

  @Selector()
  static ethanQuestions(state: EthanStateModel) {
    return state.ethanQuestions;
  }

  @Action(GetEthanChats)
  getEthanChats(ctx: StateContext<EthanStateModel>, action: GetEthanChats) {
    return this.ethanService.getEthanChats(action.searchText).pipe(
      tap((response) => {
        ctx.patchState({ ethanChats: response.ethan_chats });
      }),
    );
  }

  @Action(GetEthanQuestions)
  getEthanQuestions(ctx: StateContext<EthanStateModel>, action: GetEthanQuestions) {
    return this.ethanService.getEthanQuestions(action.chatId).pipe(
      tap((response) => {
        ctx.patchState({ ethanQuestions: response.ethan_messages ? response.ethan_messages.reverse() : [] });
      }),
    );
  }

  @Action(AskEthan)
  askEthan(ctx: StateContext<EthanStateModel>, action: AskEthan) {
    return this.ethanService.askEthan(action.chatId, action.ethanQuestion).pipe(
      tap((response) => {
        const currentQuestions = ctx.getState().ethanQuestions || [];
        const updatedQuestions = [...currentQuestions, response.ethan_message];
        ctx.patchState({ ethanQuestions: updatedQuestions });
      }),
    );
  }

  @Action(UpdateEthanAnswer)
  updateEthanAnswer(ctx: StateContext<EthanStateModel>, action: UpdateEthanAnswer) {
    return this.ethanService.updateEthanAnswer(action.chatId, action.messageId, action.answer).pipe(
      tap(() => {
        const currentQuestions = ctx.getState().ethanQuestions || [];
      }),
    );
  }

  @Action(CreateEthanChat)
  createEthanChat(ctx: StateContext<EthanStateModel>, action: CreateEthanChat) {
    return this.ethanService.createEthanChat(action.chatName).pipe(
      tap((response) => {
        const currentChats = ctx.getState().ethanChats || [];
        ctx.patchState({ ethanChats: [...currentChats, response.ethan_chat] });
      }),
    );
  }

  @Action(DeleteEthanChat)
  deleteEthanChat(ctx: StateContext<EthanStateModel>, action: DeleteEthanChat) {
    return this.ethanService.deleteEthanChat(action.chatId).pipe(
      tap(() => {
        const currentChats = ctx.getState().ethanChats;
        ctx.patchState({ ethanChats: currentChats?.filter((chat) => chat.id !== action.chatId) || null });
      }),
    );
  }

  @Action(UpdateEthanQuestion)
  updateEthanQuestion(ctx: StateContext<EthanStateModel>, action: UpdateEthanQuestion) {
    return this.ethanService.updateEthanQuestion(action.ethanQuestion).pipe(
      tap((response) => {
        const ethanQuestions = ctx.getState().ethanQuestions || [];
        const index = ethanQuestions.findIndex((q) => q.id === response.ethan_question.id);
        ethanQuestions[index] = response.ethan_question;
        ctx.patchState({ ethanQuestions });
      }),
    );
  }
}
