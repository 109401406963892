import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EthanChat, EthanMessage } from '@core/models/ethan.types';
import { AccountState } from '@state/account/state';
import { Store } from '@ngxs/store';
import { User } from 'app/core/models/user.types';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class EthanService {
  constructor(private http: HttpClient, private store: Store) {}

  getAccountId(): string {
    const account = this.store.selectSnapshot(AccountState.getAccount); // Extract account details
    return account.id;
  }

  getEthanChats(searchText?: string): Observable<{ ethan_chats: EthanChat[] }> {
    const accountId = this.getAccountId();
    return this.http.get<{ ethan_chats: EthanChat[] }>(`accounts/${accountId}/ethan_chats?search=${searchText ?? ''}`);
  }

  getEthanQuestions(chatId: number): Observable<{ ethan_messages: EthanMessage[] }> {
    const accountId = this.getAccountId();
    return this.http.get<{ ethan_messages: EthanMessage[] }>(
      `accounts/${accountId}/ethan_chats/${chatId}/ethan_messages`,
    );
  }

  askEthan(chatId: number, ethanQuestion: string): Observable<any> {
    const accountId = this.getAccountId();
    return this.http.post<any>(`accounts/${accountId}/ethan_chats/${chatId}/ethan_messages`, {
      question: ethanQuestion,
    });
  }

  updateEthanAnswer(chatId: number, messageId: number, answer: string): Observable<any> {
    const accountId = this.getAccountId();
    return this.http.put<any>(`accounts/${accountId}/ethan_chats/${chatId}/ethan_messages/${messageId}`, {
      answer: answer,
    });
  }

  createEthanChat(chatName: string): Observable<any> {
    const accountId = this.getAccountId();
    return this.http.post<any>(`accounts/${accountId}/ethan_chats`, { ethan_chat: { name: chatName } });
  }

  deleteEthanChat(chatId: number): Observable<any> {
    const accountId = this.getAccountId();
    return this.http.delete<any>(`accounts/${accountId}/ethan_chats/${chatId}`);
  }

  updateEthanQuestion(ethanQuestion: EthanMessage): Observable<any> {
    const accountId = this.getAccountId();
    return this.http.put<any>(`accounts/${accountId}/ethan/${ethanQuestion.id}`, {
      ethan_question: ethanQuestion,
    });
  }
  async ethanPostStream(url: string, body: any, user: User, chatId?: number): Promise<ReadableStream<Uint8Array>> {
    const accountId = this.getAccountId();
    body = { ...body, thread_id: `${accountId}${user.id}${chatId}` };
    const headers = new Headers({
      'Content-Type': 'application/json',
      authorization: environment.chatAssistant.authorization,
    });
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });
    return response.body;
  }
}
