import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
// import { TreoAutogrowModule } from 'tsui/@treo';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import {
  MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY as MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  MatLegacyAutocompleteModule as MatAutocompleteModule,
} from '@angular/material/legacy-autocomplete';
import { Overlay } from '@angular/cdk/overlay';
import { MentionModule } from 'angular-mentions';

import { CurrencyMaskModule } from 'ng2-currency-mask';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

import { ChartComponent } from './chart.component';
import { ChartBarComponent } from './bar/bar.component';
import { ChartDonutComponent } from './donut/donut.component';
import { ChartLineComponent } from './line/line.component';
import { ChartMetricComponent } from './metric/metric.component';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TreoAutogrowModule } from 'tsui/@treo';
import { DirectivesModule } from '@shared/directives/directives.module';

@NgModule({
  declarations: [ChartComponent, ChartBarComponent, ChartDonutComponent, ChartLineComponent, ChartMetricComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgApexchartsModule,
    MatMenuModule,
    MatMomentDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatRippleModule,
    MentionModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    TreoAutogrowModule,
    PipesModule,
    CurrencyMaskModule,
    RouterModule,
    MatTooltipModule,
    NgxMaskDirective,
    NgxMaskPipe,
    DirectivesModule,
  ],
  exports: [ChartComponent, ChartBarComponent, ChartDonutComponent, ChartLineComponent, ChartMetricComponent],
  providers: [
    provideNgxMask(),
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => {
        return () => overlay.scrollStrategies.block();
      },
      deps: [Overlay],
    },
  ],
})
export class ChartModule {}
