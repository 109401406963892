import { AsyncPipe, NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  standalone: true,
  imports: [RouterModule, AsyncPipe, RouterModule, NgFor],
  template: `
    @if (tags.length > 0) {
    <div class="tags-container">
      @for (tag of tags; track $index) {
      <span class="tag">{{ tag }}</span>
      }
    </div>
    }
  `,
  styles: [
    `
      .tags-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
      }
      .tag {
        display: inline-block;
        background-color: #ebf5fe;
        color: #333;
        padding: 2px 8px;
        margin: 8px 2px 0px 0px;
        border-radius: 4px;
        font-size: 13px;
        height: 24px;
        line-height: 20px;
        text-align: center;
      }
    `,
  ],
})
export class TableTagsRendererComponent implements ICellRendererAngularComp {
  tags: string[] = [];

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.updateTags(params.value);
  }

  refresh(params: ICellRendererParams) {
    this.updateTags(params.value);
    return true;
  }

  private updateTags(value: any): void {
    if (typeof value === 'string') {
      this.tags = value ? value.split(',').map((tag) => tag.trim()) : [];
    } else if (Array.isArray(value)) {
      this.tags = value;
    } else {
      this.tags = value ? [value.toString()] : [];
    }
  }
}
