import { Router } from '@angular/router';
import { Module } from '@core/enums';
import { AuthService } from '@core/services';
import { APP_PRIVATE_ROUTES } from 'app/app.routing';

export const unauthorizedHandler = (authService: AuthService, router: Router): void => {
  for (let type of Object.values(Module)) {
    const anyPermission = authService.hasAnyPermissionInModule(type);
    if (anyPermission) {
      const routes = APP_PRIVATE_ROUTES;
      const redirectTo = routes.find((r) => r.data?.module === type);
      router.navigate([redirectTo.path]);
      return;
    }
  }

  router.navigate(['/unauthorized']);
};
