import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { StateBase } from 'app/state/state-base';
import { RoleService } from '../services/roles.service';
import { tap, withLatestFrom } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { Settings } from './actions';
import { RolePermission, SelectablePermission } from '../roles-permissions.types';
import { Role } from 'app/core/models';
import { PermissionService } from '../services/permission.service';
import { ReservedRole } from 'app/core/enums';

export interface PermissionStateModel {
  roles: RolePermission[];
  permissions: SelectablePermission[];
}

@State({
  name: new StateToken<PermissionStateModel>('settingsState'),
  defaults: { roles: null, permissions: null },
})
@Injectable()
export class SettingsRolesPermissionState extends StateBase {
  constructor(
    protected readonly store: Store,
    private readonly roleService: RoleService,
    private readonly permissionService: PermissionService,
  ) {
    super(store);
  }

  @Selector()
  static getRoles(state: PermissionStateModel) {
    return state.roles;
  }

  @Selector()
  static getPermissions(state: PermissionStateModel) {
    return state.permissions;
  }

  @Action(Settings.RolesPermissions.SetRoles)
  setRoles(context: StateContext<PermissionStateModel>) {
    return this.roleService.getByAccountId(this.accountId).pipe(
      withLatestFrom(this.accountService.userAssociations$),
      tap(([roles, userAssociations]) => {
        const usersCountByRole = userAssociations.reduce((prev, curr) => {
          if (!curr.role_id) {
            return prev;
          }

          prev[curr.role_id] !== undefined ? (prev[curr.role_id] += 1) : (prev[curr.role_id] = 1);

          return prev;
        }, {});

        context.setState(
          patch<PermissionStateModel>({
            roles: [
              ...[ReservedRole.Admin, ReservedRole.Standard, ReservedRole.Limited, ReservedRole.Collaborator].map(
                (name) => roles.find((role) => name === role.name),
              ),
              ...roles.filter((role) => role.account_id).sort((a, b) => a.id - b.id),
            ].map((role) => ({ ...role, userCount: usersCountByRole[role.id] })),
          }),
        );
      }),
    );
  }

  @Action(Settings.RolesPermissions.SetPermissions)
  setPermissions(context: StateContext<PermissionStateModel>) {
    return this.permissionService.get().pipe(
      tap((permissions) => {
        context.setState(
          patch<PermissionStateModel>({
            permissions: permissions.map((permission) => ({ ...permission, selected: false })),
          }),
        );
      }),
    );
  }

  @Action(Settings.RolesPermissions.UpdatePermissions)
  updatePermissions(
    context: StateContext<PermissionStateModel>,
    { model }: { model: Settings.RolesPermissions.UpdatePermissionsModel },
  ) {
    const { roleId, permissionsRoles } = model;
    return this.permissionService.save(this.accountId, roleId, permissionsRoles).pipe(
      tap(() => {
        this.store.dispatch(new Settings.RolesPermissions.SetRoles());
      }),
    );
  }

  @Action(Settings.RolesPermissions.CreateRole)
  createRole(context: StateContext<PermissionStateModel>, payload: { model: Partial<Role> }) {
    return this.roleService
      .create(payload.model, this.accountId)
      .pipe(tap(() => this.store.dispatch(new Settings.RolesPermissions.SetRoles())));
  }

  @Action(Settings.RolesPermissions.EditRole)
  editRole(context: StateContext<PermissionStateModel>, payload: { model: Partial<Role> }) {
    return this.roleService
      .edit(payload.model, this.accountId)
      .pipe(tap(() => this.store.dispatch(new Settings.RolesPermissions.SetRoles())));
  }

  @Action(Settings.RolesPermissions.DeleteRole)
  deleteRole(context: StateContext<PermissionStateModel>, payload: { roleId: number }) {
    return this.roleService
      .delete(payload.roleId, this.accountId)
      .pipe(tap(() => this.store.dispatch(new Settings.RolesPermissions.SetRoles())));
  }
}
