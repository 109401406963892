export class GetEthanChats {
  static readonly type = '[Ethan] Get Ethan Chats';
  constructor(public searchText?: string) {}
}

export class GetEthanQuestions {
  static readonly type = '[Ethan] Get Ethan Questions';
  constructor(public chatId: number) {}
}

export class AskEthan {
  static readonly type = '[Ethan] Ask Ethan';
  constructor(public ethanQuestion: string, public chatId: number) {}
}

export class UpdateEthanAnswer {
  static readonly type = '[Ethan] Update Ethan Answer';
  constructor(public answer: any, public chatId: number, public messageId: number) {}
}

export class CreateEthanChat {
  static readonly type = '[Ethan] Create Ethan Chat';
  constructor(public chatName: string) {}
}

export class DeleteEthanChat {
  static readonly type = '[Ethan] Delete Ethan Chat';
  constructor(public chatId: number) {}
}

export class UpdateEthanQuestion {
  static readonly type = '[Ethan] Update Ethan Question';
  constructor(public ethanQuestion: any) {}
}
export class EthanPostStream {
  static readonly type = '[Ethan] Post Stream';
  constructor(public url: string, public body: any, public user: any, public chatId?: number) {}
}
