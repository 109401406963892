import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Permission } from 'app/core/models/permission';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionResponse } from '../roles-permissions.types';
import { CacheBuster } from 'ts-cacheable';
import { getByAccountIdCacheBuster$ } from './roles.service';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  constructor(private readonly httpClient: HttpClient) {}

  get(): Observable<Permission[]> {
    return this.httpClient.get<PermissionResponse>('permissions').pipe(map(({ permissions }) => permissions));
  }

  @CacheBuster({ cacheBusterNotifier: getByAccountIdCacheBuster$ })
  save(
    accountId: number,
    roleId: number,
    permissionsRoles: Partial<{ permission_id: number; permission_value: string }>[],
  ): Observable<void> {
    return this.httpClient.post<void>(`accounts/${accountId}/roles/${roleId}/permissions`, {
      role: {
        permissions_roles: permissionsRoles,
      },
    });
  }
}
