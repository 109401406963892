<div *ngIf="chat_selected" class="flex flex-col">
  <div class="ethan-title">
    <div class="flex items-center gap-6">
      <button mat-button (click)="onBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      {{ chat_selected.name }}
    </div>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_horiz</mat-icon>
    </button>

    <mat-menu #menu="matMenu" class="ethan-select-dropdown">
      <button mat-menu-item (click)="onDelete(chat_selected.id)">
        <mat-icon style="color: white">delete</mat-icon>
        <span>Delete</span>
      </button>
    </mat-menu>
  </div>

  <div class="flex overflow-y-auto flex-col-reverse chat-content">
    <div class="flex flex-col flex-auto shrink p-6 bg-card dark:bg-transparent chat-container">
      <div
        *ngIf="ethanQuestions.length <= 0 && !messagesLoading"
        class="h-full flex justify-center items-center flex-col gap-4"
      >
        <div
          class="bottom-0 text-white -right-1 -mr-px ethan-logo h-14 w-14 rounded-full flex justify-center items-center text-xl"
        >
          E
        </div>
        <div class="text-white text-base font-bold">How can I help you today?</div>
      </div>
      <ng-container *ngFor="let ethanQuestion of ethanQuestions; let i = index; let last = last">
        <div
          *ngIf="
            (i === 0 || (ethanQuestion.updated_at | date : 'MMMM dd, yyyy')) !==
            (ethanQuestions[i - 1]?.updated_at | date : 'MMMM dd, yyyy')
          "
          class="my-0.5 text-sm font-medium text-secondary mr-3 flex"
          style="justify-content: center"
        >
          <div class="ethan-date p-1 rounded-lg">{{ ethanQuestion.updated_at | date : 'MMMM dd, yyyy' }}</div>
        </div>
        <div class="flex flex-col mt-3">
          <div class="relative pr-3 py-2 rounded-lg flex gap-4 text-white">
            <div>
              <div class="bottom-0 text-white -right-1 -mr-px h-8 w-8 rounded-2xl flex justify-center items-center">
                <img class="h-8 w-8 rounded-full" *ngIf="currentUser.avatar_url" [src]="currentUser.avatar_url" />
                <div class="no-avatar h-8 w-8 rounded-full" *ngIf="!currentUser.avatar_url">
                  {{ currentUser.full_name?.charAt(0) }}
                </div>
              </div>
            </div>

            <div class="min-w-4 leading-5 mt-1">{{ ethanQuestion.question }}</div>
          </div>
        </div>
        <div *ngIf="ethanQuestion.answer; else noAnswer" class="flex flex-col items-start mt-2">
          <div class="relative pr-3 py-2 rounded-lg flex gap-4">
            <div>
              <div
                class="bottom-0 text-white -right-1 -mr-px ethan-logo h-8 w-8 rounded-2xl flex justify-center items-center"
              >
                E
              </div>
            </div>
            <markdown class="min-w-4 leading-5 text-white mt-1" [data]="ethanQuestion.answer"></markdown>
          </div>
        </div>
        <ng-template #noAnswer>
          <div *ngIf="!last || !isLoading" class="flex flex-col items-start mt-2">
            <div class="relative pr-3 py-2 rounded-lg flex gap-4">
              <div>
                <div
                  class="bottom-0 text-white -right-1 -mr-px ethan-logo h-8 w-8 rounded-2xl flex justify-center items-center"
                >
                  E
                </div>
              </div>
              <markdown class="min-w-4 leading-5 text-white mt-1">Sorry, unable to fetch answer</markdown>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <div *ngIf="isLoading" class="mt-1">
        <mat-progress-spinner [diameter]="16" [mode]="'indeterminate'"></mat-progress-spinner>
      </div>
    </div>
  </div>

  <div class="flex p-1 dark:bg-transparent">
    <div class="input-area">
      <form [formGroup]="ethanForm" class="px-6">
        <div
          class="form-control-wrapper form-control-with-filter-inline-button has-icon flex justify-content-between ethan-question w-full p-0.5 ethan-input-gradient"
        >
          <div class="flex w-full ethan-input-wrapper">
            <input
              type="text"
              autosize
              placeholder="Send a message"
              [formControlName]="'question'"
              rows="1"
              class="form-control w-full ethan-input text-white p-1"
              (keyup.enter)="askQuestionToEthan()"
              [disabled]="isLoading"
            />

            <button
              *ngIf="!isLoading"
              mat-icon-button
              matSuffix
              (click)="askQuestionToEthan()"
              type="button"
              title="Submit"
              [disabled]="isLoading || !ethanForm.value.question"
            >
              <mat-icon style="color: white" [svgIcon]="'send'" class="mt-2"></mat-icon>
            </button>
            <button *ngIf="isLoading" mat-icon-button matSuffix (click)="stopReading()" type="button" title="Stop">
              <mat-icon [svgIcon]="'stop'"></mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div *ngIf="!chat_selected" class="flex flex-col py-8 px-6 justify-between h-full">
  <div class="flex flex-col max-h-10/12 flex-1">
    <div class="ethan-search-wrapper relative">
      <img class="absolute top-2 h-5 w-5 left-1.5" src="assets/icons/ethan-chat-search-icon.svg" />
      <input [formControl]="searchControl" class="search-input" placeholder="Search..." />
    </div>
    <div class="text-gray-400 font-bold text-sm mt-6">CHATS</div>
    <div
      *ngIf="ethanChats.length <= 0 && !chatsLoading && !createNewInputVisible"
      class="h-full flex justify-center items-center flex-col gap-4"
    >
      <div
        class="bottom-0 text-white -right-1 -mr-px ethan-logo h-14 w-14 rounded-full flex justify-center items-center text-xl"
      >
        E
      </div>
      <div class="text-white text-base font-bold">Start a New Conversation</div>
    </div>
    <div
      *ngIf="ethanChats.length > 0 || createNewInputVisible"
      #ethanChatContainer
      class="h-full overflow-scroll flex flex-col"
    >
      <div *ngFor="let chat of ethanChats" class="text-white text-base font-medium flex flex-col mt-3">
        <div class="w-full hover:bg-gray-800 pointer rounded-md p-2 flex flex-row items-center">
          <div (click)="toggleScreen(chat.id)" class="flex-1">
            {{ chat.name }}
          </div>
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_horiz</mat-icon>
          </button>

          <mat-menu #menu="matMenu" class="ethan-select-dropdown">
            <button mat-menu-item (click)="onDelete(chat.id)">
              <mat-icon style="color: white">delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div *ngIf="createNewInputVisible">
        <input
          #newChatInput
          [formControl]="newChatControl"
          (keydown.esc)="hideInput()"
          (keydown.enter)="createNewChat()"
          class="ethan-add-new-chat"
          placeholder="Add new chat"
        />
      </div>
    </div>
  </div>
  <div
    (click)="onCreateNewClick()"
    class="cursor-pointer w-full flex justify-center items-center text-white text-base ethan-input-gradient h-10"
  >
    Create new chat
  </div>
</div>
