import { NgModule } from '@angular/core';
import {
  AuthorizeDirective,
  BackButtonDirective,
  DebounceClickDirective,
  DynamicFormRowDirective,
  GooglePlacesDirective,
  ResizeColumnDirective,
  TooltipIfEllipsisDirective,
  TsEmptyDefaultDirective,
  CellTextWrapDirective,
} from './index';
import { NewUiDirective } from './new-ui.directive';
import { MatTooltip } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AuthorizeDirective,
    BackButtonDirective,
    DebounceClickDirective,
    DynamicFormRowDirective,
    GooglePlacesDirective,
    ResizeColumnDirective,
    TooltipIfEllipsisDirective,
    NewUiDirective,
    TsEmptyDefaultDirective,
    CellTextWrapDirective,
  ],
  exports: [
    AuthorizeDirective,
    BackButtonDirective,
    DebounceClickDirective,
    DynamicFormRowDirective,
    GooglePlacesDirective,
    ResizeColumnDirective,
    TooltipIfEllipsisDirective,
    NewUiDirective,
    TsEmptyDefaultDirective,
    CellTextWrapDirective,
  ],
  providers: [MatTooltip],
})
export class DirectivesModule {}
