export enum Permission {
  PROJECT_DASHBOARD_EDIT_LAYOUT = 'PROJECT_DASHBOARD_EDIT_LAYOUT',
  SETTINGS_ACCESS = 'SETTINGS_ACCESS',
  PROJECT_ADD_USER = 'PROJECT_ADD_USER',
  PROJECT_PIPELINE_CHANGE_VISIBLE_COLUMNS = 'PROJECT_PIPELINE_CHANGE_VISIBLE_COLUMNS',
  PROPERTY_DASHBOARD_EDIT_LAYOUT = 'PROPERTY_DASHBOARD_EDIT_LAYOUT',
  PROJECT_PIPELINE_ADD_USER = 'PROJECT_PIPELINE_ADD_USER',
  PROJECT_ACCESS_ALL = 'PROJECT_ACCESS_ALL',
  PROJECT_SHARE = 'PROJECT_SHARE',
  PIPELINE_MANAGE = 'PIPELINE_MANAGE',
  PIPELINE_EDIT_DYNAMIC_FILTER = 'PIPELINE_EDIT_DYNAMIC_FILTER',
  DASHBOARDS_VIEW = 'DASHBOARDS_VIEW_OLD',
  DASHBOARDS_CREATE_EDIT = 'DASHBOARDS_CREATE_EDIT',
  DASHBOARDS_DELETE = 'DASHBOARDS_DELETE_OLD',
  PROJECT_ACCESS_ALL_PIPELINES = 'PROJECT_ACCESS_ALL_PIPELINES',
  PIPELINE_DEAL_FORM_EDIT = 'PIPELINE_DEAL_FORM_EDIT',
  DETAILS_TAB = 'DETAILS_TAB',
  FILES_TAB = 'FILES_TAB',
  TASKS_TAB = 'TASKS_TAB',
  NOTES_TAB = 'NOTES_TAB',
  INSIGHTS_TAB = 'INSIGHTS_TAB',
  CONTACTS_TAB = 'CONTACTS_TAB',
  CUSTOM_OBJECTS_TAB = 'CUSTOM_OBJECTS_TAB',
  CONTROL_GROUP_BY_DROPDOWN = 'CONTROL_GROUP_BY_DROPDOWN',
  CONTACTS_ACCESS_ALL = 'CONTACTS_ACCESS_ALL',
  CONTACTS_CONTROL_COLUMNS = 'CONTACTS_CONTROL_COLUMNS',
  CONTACTS_ADD = 'CONTACTS_ADD',
  CONTACTS_MERGE = 'CONTACTS_MERGE',
  CONTACTS_DELETE = 'CONTACTS_DELETE_OLD',
  CONTACT_FORM_EDIT = 'CONTACT_FORM_EDIT',
  COMPANY_FORM_EDIT = 'COMPANY_FORM_EDIT',
  TASK_LIST_ACCESS_ALL = 'TASK_LIST_ACCESS_ALL',
  TASK_LIST_ADD_USER = 'TASK_LIST_ADD_USER',
  TASK_LIST_GLOBAL = 'TASK_LIST_GLOBAL',
  TASK_LIST_AGGREGATE = 'TASK_LIST_AGGREGATE',
  TASK_GLOBAL_SETTINGS = 'TASK_GLOBAL_SETTINGS',
  TASK_LIST_APPROVALS = 'TASK_LIST_APPROVALS',
  TASK_LIST_CHANGE_VISIBLE_COLUMNS = 'TASK_LIST_CHANGE_VISIBLE_COLUMNS',
  TASK_LIST_EDIT_LAYOUT = 'TASK_LIST_EDIT_LAYOUT',
  TASK_LIST_VIEWS = 'TASK_LIST_VIEWS',
  PROPERTY_DETAILS_TAB = 'PROPERTY_DETAILS_TAB',
  PROPERTY_FILES_TAB = 'PROPERTY_FILES_TAB',
  PROPERTY_NOTES_TAB = 'PROPERTY_NOTES_TAB',
  PROPERTY_LEASES_TAB = 'PROPERTY_LEASES_TAB',
  PROPERTY_DEALS_TAB = 'PROPERTY_DEALS_TAB',
  PROPERTY_NEARBY_TAB = 'PROPERTY_NEARBY_TAB',
  PROPERTY_CUSTOM_OBJECTS_TAB = 'PROPERTY_CUSTOM_OBJECTS_TAB',
  PROPERTY_CREATE = 'PROPERTY_CREATE',
  MAPS_ACCESS_ALL = 'MAPS_ACCESS_ALL',
  MAPS_EDIT_LAYOUT = 'MAPS_EDIT_LAYOUT',
  REPORTS_PDF = 'REPORTS_PDF',
  REPORTS_REPORT_BUILDER = 'REPORTS_REPORT_BUILDER',
  SETTINGS_USERS = 'SETTINGS_USERS',
  SETTINGS_PERMISSIONS = 'SETTINGS_PERMISSIONS',
  SETTINGS_DROPDOWNS = 'SETTINGS_DROPDOWNS',
  SETTINGS_FIELDS = 'SETTINGS_FIELDS',
  SETTINGS_TASK_TEMPLATES = 'SETTINGS_TASK_TEMPLATES',
  SETTINGS_TASK_ROLES = 'SETTINGS_TASK_ROLES',
  SETTINGS_SHEETS = 'SETTINGS_SHEETS',
  SETTINGS_APPS = 'SETTINGS_APPS',
  SETTINGS_CODE = 'SETTINGS_CODE',
  SETTINGS_IMPORTS = 'SETTINGS_IMPORTS',
  SETTINGS_OBJECT_MANAGER = 'SETTINGS_OBJECT_MANAGER',
  TASK_LIST_COLLABORATOR = 'TASK_LIST_COLLABORATOR',
  DEAL_TASK_SETTINGS = 'DEAL_TASK_SETTINGS',
  DEALS_ADD = 'DEALS_ADD',
  DEALS_DELETE = 'DEALS_DELETE_OLD',
  PROPERTY_TASKS_TAB = 'PROPERTY_TASKS_TAB',
  PRIVATE_PROPERTY_TASKS = 'PRIVATE_PROPERTY_TASKS',
  PRIVATE_DEAL_TASKS = 'PRIVATE_DEAL_TASKS',
  SETTINGS_AUTOMATIONS = 'SETTINGS_AUTOMATIONS',
  PROPERTY_DELETE = 'PROPERTY_DELETE',
  PROPERTY_FORM_EDIT = 'PROPERTY_FORM_EDIT',
}

export enum NewPermission {
  SETTINGS_MANAGE_WORKSPACE = 'SETTINGS_MANAGE_WORKSPACE',
  SETTINGS_MANAGE_USERS = 'SETTINGS_MANAGE_USERS',
  SETTINGS_MANAGE_TEAMS = 'SETTINGS_MANAGE_TEAMS',
  SETTINGS_MANAGE_PERMISSIONS = 'SETTINGS_MANAGE_PERMISSIONS',
  SETTINGS_MANAGE_ROLES = 'SETTINGS_MANAGE_ROLES',
  SETTINGS_MANAGE_DROPDOWNS = 'SETTINGS_MANAGE_DROPDOWNS',
  SETTINGS_MANAGE_OBJECTS_MANAGER = 'SETTINGS_MANAGE_OBJECTS_MANAGER',
  SETTINGS_MANAGE_FIELDS = 'SETTINGS_MANAGE_FIELDS',
  SETTINGS_MANAGE_TASK_TEMPLATES = 'SETTINGS_MANAGE_TASK_TEMPLATES',
  SETTINGS_MANAGE_SHEETS = 'SETTINGS_MANAGE_SHEETS',
  SETTINGS_MANAGE_APPS = 'SETTINGS_MANAGE_APPS',
  SETTINGS_MANAGE_CODE = 'SETTINGS_MANAGE_CODE',
  SETTINGS_MANAGE_IMPORTS = 'SETTINGS_MANAGE_IMPORTS',
  SETTINGS_MANAGE_AUTOMATIONS = 'SETTINGS_MANAGE_AUTOMATIONS',
  SETTINGS_MANAGE_EXPORTS = 'SETTINGS_MANAGE_EXPORTS',
  SETTINGS_MANAGE_ACCOUNT_HOOKS = 'SETTINGS_MANAGE_ACCOUNT_HOOKS',
  SETTINGS_MANAGE_LAYOUTS = 'SETTINGS_MANAGE_LAYOUTS',

  DEALS_VIEW = 'DEALS_VIEW',
  DEALS_CREATE = 'DEALS_CREATE',
  DEALS_EDIT = 'DEALS_EDIT',
  DEALS_DELETE = 'DEALS_DELETE',
  DEALS_MANAGE_ADD_FORM = 'DEALS_MANAGE_ADD_FORM',
  DEALS_MANAGE_LAYOUT = 'DEALS_MANAGE_LAYOUT',
  DEALS_PUBLIC_CUSTOM_VIEWS = 'DEALS_PUBLIC_CUSTOM_VIEWS',
  DEALS_SHARING_RECORDS = 'DEALS_SHARING_RECORDS',

  PROPERTIES_VIEW = 'PROPERTIES_VIEW',
  PROPERTIES_CREATE = 'PROPERTIES_CREATE',
  PROPERTIES_EDIT = 'PROPERTIES_EDIT',
  PROPERTIES_DELETE = 'PROPERTIES_DELETE',
  PROPERTIES_MANAGE_ADD_FORM = 'PROPERTIES_MANAGE_ADD_FORM',
  PROPERTIES_MANAGE_LAYOUT = 'PROPERTIES_MANAGE_LAYOUT',
  PROPERTIES_PUBLIC_CUSTOM_VIEWS = 'PROPERTIES_PUBLIC_CUSTOM_VIEWS',
  PROPERTIES_SHARING_RECORDS = 'PROPERTIES_SHARING_RECORDS',

  LOAN_QUOTES_VIEW = 'LOAN_QUOTES_VIEW',
  LOAN_QUOTES_CREATE = 'LOAN_QUOTES_CREATE',
  LOAN_QUOTES_EDIT = 'LOAN_QUOTES_EDIT',
  LOAN_QUOTES_DELETE = 'LOAN_QUOTES_DELETE',
  LOAN_QUOTES_MANAGE_ADD_FORM = 'LOAN_QUOTES_MANAGE_ADD_FORM',
  LOAN_QUOTES_MANAGE_LAYOUT = 'LOAN_QUOTES_MANAGE_LAYOUT',
  LOAN_QUOTES_PUBLIC_CUSTOM_VIEWS = 'LOAN_QUOTES_PUBLIC_CUSTOM_VIEWS',
  LOAN_QUOTES_SHARING_RECORDS = 'LOAN_QUOTES_SHARING_RECORDS',

  LEASES_VIEW = 'LEASES_VIEW',
  LEASES_CREATE = 'LEASES_CREATE',
  LEASES_EDIT = 'LEASES_EDIT',
  LEASES_DELETE = 'LEASES_DELETE',
  LEASES_MANAGE_ADD_FORM = 'LEASES_MANAGE_ADD_FORM',
  LEASES_MANAGE_LAYOUT = 'LEASES_MANAGE_LAYOUT',
  LEASES_PUBLIC_CUSTOM_VIEWS = 'LEASES_PUBLIC_CUSTOM_VIEWS',
  LEASES_SHARING_RECORDS = 'LEASES_SHARING_RECORDS',

  DISPOSITIONS_VIEW = 'DISPOSITIONS_VIEW',
  DISPOSITIONS_CREATE = 'DISPOSITIONS_CREATE',
  DISPOSITIONS_EDIT = 'DISPOSITIONS_EDIT',
  DISPOSITIONS_DELETE = 'DISPOSITIONS_DELETE',
  DISPOSITIONS_MANAGE_ADD_FORM = 'DISPOSITIONS_MANAGE_ADD_FORM',
  DISPOSITIONS_MANAGE_LAYOUT = 'DISPOSITIONS_MANAGE_LAYOUT',
  DISPOSITIONS_PUBLIC_CUSTOM_VIEWS = 'DISPOSITIONS_PUBLIC_CUSTOM_VIEWS',
  DISPOSITIONS_SHARING_RECORDS = 'DISPOSITIONS_SHARING_RECORDS',

  FILES_VIEW = 'FILES_VIEW',
  FILES_CREATE = 'FILES_CREATE',
  FILES_EDIT = 'FILES_EDIT',
  FILES_DELETE = 'FILES_DELETE',
  FILES_SHARING_RECORDS = 'FILES_SHARING_RECORDS',

  COMPANIES_VIEW = 'COMPANIES_VIEW',
  COMPANIES_CREATE = 'COMPANIES_CREATE',
  COMPANIES_EDIT = 'COMPANIES_EDIT',
  COMPANIES_DELETE = 'COMPANIES_DELETE',
  COMPANIES_MANAGE_ADD_FORM = 'COMPANIES_MANAGE_ADD_FORM',
  COMPANIES_MANAGE_LAYOUT = 'COMPANIES_MANAGE_LAYOUT',
  COMPANIES_PUBLIC_CUSTOM_VIEWS = 'COMPANIES_PUBLIC_CUSTOM_VIEWS',
  COMPANIES_SHARING_RECORDS = 'COMPANIES_SHARING_RECORDS',

  CONTACTS_VIEW = 'CONTACTS_VIEW',
  CONTACTS_CREATE = 'CONTACTS_CREATE',
  CONTACTS_EDIT = 'CONTACTS_EDIT',
  CONTACTS_DELETE = 'CONTACTS_DELETE',
  CONTACTS_MANAGE_ADD_FORM = 'CONTACTS_MANAGE_ADD_FORM',
  CONTACTS_MANAGE_LAYOUT = 'CONTACTS_MANAGE_LAYOUT',
  CONTACTS_PUBLIC_CUSTOM_VIEWS = 'CONTACTS_PUBLIC_CUSTOM_VIEWS',
  CONTACTS_SHARING_RECORDS = 'CONTACTS_SHARING_RECORDS',

  ENGAGEMENTS_VIEW = 'ENGAGEMENTS_VIEW',
  ENGAGEMENTS_CREATE = 'ENGAGEMENTS_CREATE',
  ENGAGEMENTS_EDIT = 'ENGAGEMENTS_EDIT',
  ENGAGEMENTS_DELETE = 'ENGAGEMENTS_DELETE',
  ENGAGEMENTS_MANAGE_ADD_FORM = 'ENGAGEMENTS_MANAGE_ADD_FORM',
  ENGAGEMENTS_MANAGE_LAYOUT = 'ENGAGEMENTS_MANAGE_LAYOUT',
  ENGAGEMENTS_PUBLIC_CUSTOM_VIEWS = 'ENGAGEMENTS_PUBLIC_CUSTOM_VIEWS',
  ENGAGEMENTS_SHARING_RECORDS = 'ENGAGEMENTS_SHARING_RECORDS',

  GLOBAL_TASK_LISTS_VIEW = 'GLOBAL_TASK_LISTS_VIEW',
  GLOBAL_TASK_LISTS_CREATE = 'GLOBAL_TASK_LISTS_CREATE',
  GLOBAL_TASK_LISTS_EDIT = 'GLOBAL_TASK_LISTS_EDIT',
  GLOBAL_TASK_LISTS_DELETE = 'GLOBAL_TASK_LISTS_DELETE',
  GLOBAL_TASK_LISTS_MANAGE_ADD_FORM = 'GLOBAL_TASK_LISTS_MANAGE_ADD_FORM',
  GLOBAL_TASKS_SHARING_RECORDS = 'GLOBAL_TASKS_SHARING_RECORDS',

  AGGREGATED_TASK_LISTS_VIEW = 'AGGREGATED_TASK_LISTS_VIEW',
  AGGREGATED_TASK_LISTS_CREATE = 'AGGREGATED_TASK_LISTS_CREATE',
  AGGREGATED_TASK_LISTS_EDIT = 'AGGREGATED_TASK_LISTS_EDIT',
  AGGREGATED_TASK_LISTS_DELETE = 'AGGREGATED_TASK_LISTS_DELETE',
  AGGREGATED_TASK_LISTS_MANAGE_ADD_FORM = 'AGGREGATED_TASK_LISTS_MANAGE_ADD_FORM',
  AGGREGATED_TASKS_PUBLIC_CUSTOM_VIEWS = 'AGGREGATED_TASKS_PUBLIC_CUSTOM_VIEWS',
  AGGREGATED_TASKS_SHARING_RECORDS = 'AGGREGATED_TASKS_SHARING_RECORDS',

  DASHBOARDS_CREATE = 'DASHBOARDS_CREATE',
  DASHBOARDS_VIEW = 'DASHBOARDS_VIEW',
  DASHBOARDS_EDIT = 'DASHBOARDS_EDIT',
  DASHBOARDS_DELETE = 'DASHBOARDS_DELETE',
  DASHBOARDS_SHARING_RECORDS = 'DASHBOARDS_SHARING_RECORDS',

  REPORT_BUILDER_CREATE = 'REPORT_BUILDER_CREATE',
  REPORTS_BUILDER_VIEW = 'REPORTS_BUILDER_VIEW',
  REPORTS_BUILDER_EDIT = 'REPORTS_BUILDER_EDIT',
  REPORTS_BUILDER_DELETE = 'REPORTS_BUILDER_DELETE',
  REPORT_BUILDER_SHARING_RECORDS = 'REPORT_BUILDER_SHARING_RECORDS',

  PDF_REPORTS_CREATE = 'PDF_REPORTS_CREATE',
  PDF_REPORTS_VIEW = 'PDF_REPORTS_VIEW',
  PDF_REPORTS_EDIT = 'PDF_REPORTS_EDIT',
  PDF_REPORTS_DELETE = 'PDF_REPORTS_DELETE',
  PDF_REPORTS_SHARING_RECORDS = 'PDF_REPORTS_SHARING_RECORDS',
}

/**
 * This mapped structure should be used while the `new_permissions` feature doesn't roll out to all accounts.
 *
 * Once the `new_permissions` feature is stable for all accounts:
 * 1. remove old permissions from `Permission` enum
 * 2. remove this map
 * 3. refactor `AuthService.hasPermission` to use the previous implementation
 */
export namespace ProvisionalPermissionsMap {
  export const permissionsMap: { [key in Permission]: string[] } = Object.freeze({
    // -------------- new permissions ----------------- //
    [NewPermission.SETTINGS_MANAGE_WORKSPACE]: ['SETTINGS_MANAGE_WORKSPACE'],
    [NewPermission.SETTINGS_MANAGE_USERS]: ['SETTINGS_MANAGE_USERS'],
    [NewPermission.SETTINGS_MANAGE_TEAMS]: ['SETTINGS_MANAGE_TEAMS'],
    [NewPermission.SETTINGS_MANAGE_PERMISSIONS]: ['SETTINGS_MANAGE_PERMISSIONS'],
    [NewPermission.SETTINGS_MANAGE_ROLES]: ['SETTINGS_MANAGE_ROLES'],
    [NewPermission.SETTINGS_MANAGE_DROPDOWNS]: ['SETTINGS_MANAGE_DROPDOWNS'],
    [NewPermission.SETTINGS_MANAGE_OBJECTS_MANAGER]: ['SETTINGS_MANAGE_OBJECTS_MANAGER'],
    [NewPermission.SETTINGS_MANAGE_FIELDS]: ['SETTINGS_MANAGE_FIELDS'],
    [NewPermission.SETTINGS_MANAGE_TASK_TEMPLATES]: ['SETTINGS_MANAGE_TASK_TEMPLATES'],
    [NewPermission.SETTINGS_MANAGE_SHEETS]: ['SETTINGS_MANAGE_SHEETS'],
    [NewPermission.SETTINGS_MANAGE_APPS]: ['SETTINGS_MANAGE_APPS'],
    [NewPermission.SETTINGS_MANAGE_CODE]: ['SETTINGS_MANAGE_CODE'],
    [NewPermission.SETTINGS_MANAGE_IMPORTS]: ['SETTINGS_MANAGE_IMPORTS'],
    [NewPermission.SETTINGS_MANAGE_AUTOMATIONS]: ['SETTINGS_MANAGE_AUTOMATIONS'],
    [NewPermission.SETTINGS_MANAGE_EXPORTS]: ['SETTINGS_MANAGE_EXPORTS'],
    [NewPermission.SETTINGS_MANAGE_ACCOUNT_HOOKS]: ['SETTINGS_MANAGE_ACCOUNT_HOOKS'],
    [NewPermission.SETTINGS_MANAGE_LAYOUTS]: ['SETTINGS_MANAGE_LAYOUTS'],

    [NewPermission.DEALS_VIEW]: ['DEALS_VIEW'],
    [NewPermission.DEALS_CREATE]: ['DEALS_CREATE'],
    // [NewPermission.DEALS_DELETE]: ['DEALS_DELETE_OLD'],
    [NewPermission.DEALS_EDIT]: ['DEALS_EDIT'],
    [NewPermission.DEALS_MANAGE_ADD_FORM]: ['DEALS_MANAGE_ADD_FORM'],
    [NewPermission.DEALS_MANAGE_LAYOUT]: ['DEALS_MANAGE_LAYOUT'],
    [NewPermission.DEALS_PUBLIC_CUSTOM_VIEWS]: ['DEALS_PUBLIC_CUSTOM_VIEWS'],
    [NewPermission.DEALS_SHARING_RECORDS]: ['DEALS_SHARING_RECORDS'],

    [NewPermission.PROPERTIES_VIEW]: ['PROPERTIES_VIEW'],
    [NewPermission.PROPERTIES_CREATE]: ['PROPERTIES_CREATE'],
    [NewPermission.PROPERTIES_EDIT]: ['PROPERTIES_EDIT'],
    [NewPermission.PROPERTIES_DELETE]: ['PROPERTIES_DELETE'],
    [NewPermission.PROPERTIES_MANAGE_ADD_FORM]: ['PROPERTIES_MANAGE_ADD_FORM'],
    [NewPermission.PROPERTIES_MANAGE_LAYOUT]: ['PROPERTIES_MANAGE_LAYOUT'],
    [NewPermission.PROPERTIES_PUBLIC_CUSTOM_VIEWS]: ['PROPERTIES_PUBLIC_CUSTOM_VIEWS'],
    [NewPermission.PROPERTIES_SHARING_RECORDS]: ['PROPERTIES_SHARING_RECORDS'],

    [NewPermission.LOAN_QUOTES_VIEW]: ['LOAN_QUOTES_VIEW'],
    [NewPermission.LOAN_QUOTES_CREATE]: ['LOAN_QUOTES_CREATE'],
    [NewPermission.LOAN_QUOTES_EDIT]: ['LOAN_QUOTES_EDIT'],
    [NewPermission.LOAN_QUOTES_DELETE]: ['LOAN_QUOTES_DELETE'],
    [NewPermission.LOAN_QUOTES_MANAGE_ADD_FORM]: ['LOAN_QUOTES_MANAGE_ADD_FORM'],
    [NewPermission.LOAN_QUOTES_MANAGE_LAYOUT]: ['LOAN_QUOTES_MANAGE_LAYOUT'],
    [NewPermission.LOAN_QUOTES_PUBLIC_CUSTOM_VIEWS]: ['LOAN_QUOTES_PUBLIC_CUSTOM_VIEWS'],
    [NewPermission.LOAN_QUOTES_SHARING_RECORDS]: ['LOAN_QUOTES_SHARING_RECORDS'],

    [NewPermission.LEASES_VIEW]: ['LEASES_VIEW'],
    [NewPermission.LEASES_CREATE]: ['LEASES_CREATE'],
    [NewPermission.LEASES_EDIT]: ['LEASES_EDIT'],
    [NewPermission.LEASES_DELETE]: ['LEASES_DELETE'],
    [NewPermission.LEASES_MANAGE_ADD_FORM]: ['LEASES_MANAGE_ADD_FORM'],
    [NewPermission.LEASES_MANAGE_LAYOUT]: ['LEASES_MANAGE_LAYOUT'],
    [NewPermission.LEASES_PUBLIC_CUSTOM_VIEWS]: ['LEASES_PUBLIC_CUSTOM_VIEWS'],
    [NewPermission.LEASES_SHARING_RECORDS]: ['LEASES_SHARING_RECORDS'],

    [NewPermission.DISPOSITIONS_VIEW]: ['DISPOSITIONS_VIEW'],
    [NewPermission.DISPOSITIONS_CREATE]: ['DISPOSITIONS_CREATE'],
    [NewPermission.DISPOSITIONS_EDIT]: ['DISPOSITIONS_EDIT'],
    [NewPermission.DISPOSITIONS_DELETE]: ['DISPOSITIONS_DELETE'],
    [NewPermission.DISPOSITIONS_MANAGE_ADD_FORM]: ['DISPOSITIONS_MANAGE_ADD_FORM'],
    [NewPermission.DISPOSITIONS_MANAGE_LAYOUT]: ['DISPOSITIONS_MANAGE_LAYOUT'],
    [NewPermission.DISPOSITIONS_PUBLIC_CUSTOM_VIEWS]: ['DISPOSITIONS_PUBLIC_CUSTOM_VIEWS'],
    [NewPermission.DISPOSITIONS_SHARING_RECORDS]: ['DISPOSITIONS_SHARING_RECORDS'],

    [NewPermission.FILES_VIEW]: ['FILES_VIEW'],
    [NewPermission.FILES_CREATE]: ['FILES_CREATE'],
    [NewPermission.FILES_EDIT]: ['FILES_EDIT'],
    [NewPermission.FILES_DELETE]: ['FILES_DELETE'],
    [NewPermission.FILES_SHARING_RECORDS]: ['FILES_SHARING_RECORDS'],

    [NewPermission.COMPANIES_VIEW]: ['COMPANIES_VIEW'],
    [NewPermission.COMPANIES_CREATE]: ['COMPANIES_CREATE'],
    [NewPermission.COMPANIES_EDIT]: ['COMPANIES_EDIT'],
    [NewPermission.COMPANIES_DELETE]: ['COMPANIES_DELETE'],
    [NewPermission.COMPANIES_MANAGE_ADD_FORM]: ['COMPANIES_MANAGE_ADD_FORM'],
    [NewPermission.COMPANIES_MANAGE_LAYOUT]: ['COMPANIES_MANAGE_LAYOUT'],
    [NewPermission.COMPANIES_PUBLIC_CUSTOM_VIEWS]: ['COMPANIES_PUBLIC_CUSTOM_VIEWS'],
    [NewPermission.COMPANIES_SHARING_RECORDS]: ['COMPANIES_SHARING_RECORDS'],

    [NewPermission.CONTACTS_VIEW]: ['CONTACTS_VIEW'],
    [NewPermission.CONTACTS_CREATE]: ['CONTACTS_CREATE'],
    [NewPermission.CONTACTS_EDIT]: ['CONTACTS_EDIT'],
    [NewPermission.CONTACTS_DELETE]: ['CONTACTS_DELETE'],
    [NewPermission.CONTACTS_MANAGE_ADD_FORM]: ['CONTACTS_MANAGE_ADD_FORM'],
    [NewPermission.CONTACTS_MANAGE_LAYOUT]: ['CONTACTS_MANAGE_LAYOUT'],
    [NewPermission.CONTACTS_PUBLIC_CUSTOM_VIEWS]: ['CONTACTS_PUBLIC_CUSTOM_VIEWS'],
    [NewPermission.CONTACTS_SHARING_RECORDS]: ['CONTACTS_SHARING_RECORDS'],

    [NewPermission.ENGAGEMENTS_VIEW]: ['ENGAGEMENTS_VIEW'],
    [NewPermission.ENGAGEMENTS_CREATE]: ['ENGAGEMENTS_CREATE'],
    [NewPermission.ENGAGEMENTS_EDIT]: ['ENGAGEMENTS_EDIT'],
    [NewPermission.ENGAGEMENTS_DELETE]: ['ENGAGEMENTS_DELETE'],
    [NewPermission.ENGAGEMENTS_MANAGE_ADD_FORM]: ['ENGAGEMENTS_MANAGE_ADD_FORM'],
    [NewPermission.ENGAGEMENTS_MANAGE_LAYOUT]: ['ENGAGEMENTS_MANAGE_LAYOUT'],
    [NewPermission.ENGAGEMENTS_PUBLIC_CUSTOM_VIEWS]: ['ENGAGEMENTS_PUBLIC_CUSTOM_VIEWS'],
    [NewPermission.ENGAGEMENTS_SHARING_RECORDS]: ['ENGAGEMENTS_SHARING_RECORDS'],

    [NewPermission.GLOBAL_TASK_LISTS_VIEW]: ['GLOBAL_TASK_LISTS_VIEW'],
    [NewPermission.GLOBAL_TASK_LISTS_CREATE]: ['GLOBAL_TASK_LISTS_CREATE'],
    [NewPermission.GLOBAL_TASK_LISTS_EDIT]: ['GLOBAL_TASK_LISTS_EDIT'],
    [NewPermission.GLOBAL_TASK_LISTS_DELETE]: ['GLOBAL_TASK_LISTS_DELETE'],
    [NewPermission.GLOBAL_TASK_LISTS_MANAGE_ADD_FORM]: ['GLOBAL_TASK_LISTS_MANAGE_ADD_FORM'],
    [NewPermission.GLOBAL_TASKS_SHARING_RECORDS]: ['GLOBAL_TASKS_SHARING_RECORDS'],

    [NewPermission.AGGREGATED_TASK_LISTS_VIEW]: ['AGGREGATED_TASK_LISTS_VIEW'],
    [NewPermission.AGGREGATED_TASK_LISTS_CREATE]: ['AGGREGATED_TASK_LISTS_CREATE'],
    [NewPermission.AGGREGATED_TASK_LISTS_EDIT]: ['AGGREGATED_TASK_LISTS_EDIT'],
    [NewPermission.AGGREGATED_TASK_LISTS_DELETE]: ['AGGREGATED_TASK_LISTS_DELETE'],
    [NewPermission.AGGREGATED_TASK_LISTS_MANAGE_ADD_FORM]: ['AGGREGATED_TASK_LISTS_MANAGE_ADD_FORM'],
    [NewPermission.AGGREGATED_TASKS_PUBLIC_CUSTOM_VIEWS]: ['AGGREGATED_TASKS_PUBLIC_CUSTOM_VIEWS'],
    [NewPermission.AGGREGATED_TASKS_SHARING_RECORDS]: ['AGGREGATED_TASKS_SHARING_RECORDS'],

    [NewPermission.DASHBOARDS_CREATE]: ['DASHBOARDS_CREATE'],
    // [NewPermission.DASHBOARDS_VIEW]: ['DASHBOARDS_VIEW_OLD'],
    [NewPermission.DASHBOARDS_EDIT]: ['DASHBOARDS_EDIT'],
    // [NewPermission.DASHBOARDS_DELETE]: ['DASHBOARDS_DELETE_OLD'],
    [NewPermission.DASHBOARDS_SHARING_RECORDS]: ['DASHBOARDS_SHARING_RECORDS'],

    [NewPermission.REPORT_BUILDER_CREATE]: ['REPORT_BUILDER_CREATE'],
    [NewPermission.REPORTS_BUILDER_VIEW]: ['REPORTS_BUILDER_VIEW'],
    [NewPermission.REPORTS_BUILDER_EDIT]: ['REPORTS_BUILDER_EDIT'],
    [NewPermission.REPORTS_BUILDER_DELETE]: ['REPORTS_BUILDER_DELETE'],
    [NewPermission.REPORT_BUILDER_SHARING_RECORDS]: ['REPORT_BUILDER_SHARING_RECORDS'],

    [NewPermission.PDF_REPORTS_CREATE]: ['PDF_REPORTS_CREATE'],
    [NewPermission.PDF_REPORTS_VIEW]: ['PDF_REPORTS_VIEW'],
    [NewPermission.PDF_REPORTS_EDIT]: ['PDF_REPORTS_EDIT'],
    [NewPermission.PDF_REPORTS_DELETE]: ['PDF_REPORTS_DELETE'],
    [NewPermission.PDF_REPORTS_SHARING_RECORDS]: ['PDF_REPORTS_SHARING_RECORDS'],
    // -------------- new permissions ----------------- //

    // ------------ old-new permissions merged ---------------- //
    [Permission.SETTINGS_ACCESS]: ['SETTINGS_ACCESS', 'SETTINGS_MANAGE_WORKSPACE', 'SETTINGS_MANAGE_EXPORTS'],
    [Permission.SETTINGS_USERS]: ['SETTINGS_USERS', 'SETTINGS_MANAGE_USERS'],
    [Permission.SETTINGS_PERMISSIONS]: ['SETTINGS_PERMISSIONS', 'SETTINGS_MANAGE_PERMISSIONS'],
    [Permission.SETTINGS_TASK_ROLES]: ['SETTINGS_TASK_ROLES', 'SETTINGS_MANAGE_ROLES'],
    [Permission.SETTINGS_DROPDOWNS]: ['SETTINGS_DROPDOWNS', 'SETTINGS_MANAGE_DROPDOWNS'],
    [Permission.SETTINGS_OBJECT_MANAGER]: ['SETTINGS_OBJECT_MANAGER', 'SETTINGS_MANAGE_OBJECTS_MANAGER'],
    [Permission.SETTINGS_FIELDS]: ['SETTINGS_FIELDS', 'SETTINGS_MANAGE_FIELDS'],
    [Permission.SETTINGS_TASK_TEMPLATES]: ['SETTINGS_TASK_TEMPLATES', 'SETTINGS_MANAGE_TASK_TEMPLATES'],
    [Permission.SETTINGS_SHEETS]: ['SETTINGS_SHEETS', 'SETTINGS_MANAGE_SHEETS'],
    [Permission.SETTINGS_APPS]: ['SETTINGS_APPS', 'SETTINGS_MANAGE_APPS'],
    [Permission.SETTINGS_CODE]: ['SETTINGS_CODE', 'SETTINGS_MANAGE_CODE'],
    [Permission.SETTINGS_IMPORTS]: ['SETTINGS_IMPORTS', 'SETTINGS_MANAGE_IMPORTS'],
    [Permission.SETTINGS_AUTOMATIONS]: ['SETTINGS_AUTOMATIONS', 'SETTINGS_MANAGE_AUTOMATIONS'],

    [Permission.PROJECT_ACCESS_ALL]: ['PROJECT_ACCESS_ALL'],
    [Permission.DEALS_ADD]: ['DEALS_ADD', 'DEALS_CREATE'],
    [Permission.DEALS_DELETE]: ['DEALS_DELETE_OLD'],
    [Permission.PIPELINE_DEAL_FORM_EDIT]: ['PIPELINE_DEAL_FORM_EDIT', 'DEALS_MANAGE_ADD_FORM'],
    [Permission.PROJECT_DASHBOARD_EDIT_LAYOUT]: ['PROJECT_DASHBOARD_EDIT_LAYOUT', 'DEALS_MANAGE_LAYOUT'],
    [Permission.PIPELINE_MANAGE]: ['PIPELINE_MANAGE', 'DEALS_PUBLIC_CUSTOM_VIEWS'],
    [Permission.PROJECT_ADD_USER]: ['PROJECT_ADD_USER', 'DEALS_SHARING_RECORDS'],
    [Permission.PROPERTY_DETAILS_TAB]: ['PROPERTY_DETAILS_TAB', 'PROPERTIES_VIEW', 'PROPERTIES_SHARING_RECORDS'],
    [Permission.PROPERTY_CREATE]: ['PROPERTY_CREATE', 'PROPERTIES_CREATE'],
    [Permission.PROPERTY_DELETE]: ['PROPERTY_DELETE', 'PROPERTIES_EDIT', 'PROPERTIES_DELETE'],
    [Permission.PROPERTY_FORM_EDIT]: [
      'PROPERTY_FORM_EDIT',
      'PROPERTIES_MANAGE_ADD_FORM',
      'PROPERTIES_PUBLIC_CUSTOM_VIEWS',
    ],
    [Permission.PROPERTY_DASHBOARD_EDIT_LAYOUT]: ['PROPERTY_DASHBOARD_EDIT_LAYOUT', 'PROPERTIES_MANAGE_LAYOUT'],

    [Permission.FILES_TAB]: ['FILES_TAB', 'FILES_VIEW'],

    [Permission.CONTACTS_ACCESS_ALL]: ['CONTACTS_ACCESS_ALL', 'COMPANIES_VIEW', 'CONTACTS_VIEW'],
    [Permission.CONTACTS_ADD]: ['CONTACTS_ADD', 'COMPANIES_CREATE', 'CONTACTS_CREATE'],
    [Permission.CONTACTS_MERGE]: ['CONTACTS_MERGE', 'COMPANIES_EDIT', 'CONTACTS_EDIT'],
    [Permission.CONTACTS_DELETE]: ['CONTACTS_DELETE_OLD', 'COMPANIES_DELETE'],
    [Permission.CONTACTS_CONTROL_COLUMNS]: ['CONTACTS_CONTROL_COLUMNS'],
    [Permission.COMPANY_FORM_EDIT]: ['COMPANY_FORM_EDIT', 'COMPANIES_MANAGE_ADD_FORM', 'COMPANIES_MANAGE_LAYOUT'],
    [Permission.CONTACT_FORM_EDIT]: ['CONTACT_FORM_EDIT', 'CONTACTS_MANAGE_ADD_FORM', 'CONTACTS_MANAGE_LAYOUT'],

    [Permission.TASK_LIST_GLOBAL]: ['TASK_LIST_GLOBAL', 'GLOBAL_TASK_LISTS_VIEW', 'GLOBAL_TASK_LISTS_CREATE'],
    [Permission.TASK_GLOBAL_SETTINGS]: ['TASK_GLOBAL_SETTINGS', 'GLOBAL_TASK_LISTS_EDIT', 'GLOBAL_TASK_LISTS_DELETE'],
    [Permission.TASK_LIST_EDIT_LAYOUT]: [
      'TASK_LIST_EDIT_LAYOUT',
      'GLOBAL_TASK_LISTS_MANAGE_ADD_FORM',
      'AGGREGATED_TASK_LISTS_MANAGE_ADD_FORM',
    ],
    [Permission.TASK_LIST_ADD_USER]: ['TASK_LIST_ADD_USER', 'GLOBAL_TASKS_SHARING_RECORDS'],
    [Permission.TASK_LIST_AGGREGATE]: [
      'TASK_LIST_AGGREGATE',
      'AGGREGATED_TASK_LISTS_VIEW',
      'AGGREGATED_TASK_LISTS_CREATE',
      'AGGREGATED_TASK_LISTS_EDIT',
      'AGGREGATED_TASK_LISTS_DELETE',
    ],
    [Permission.TASK_LIST_VIEWS]: ['TASK_LIST_VIEWS', 'AGGREGATED_TASKS_PUBLIC_CUSTOM_VIEWS'],
    [Permission.TASK_LIST_COLLABORATOR]: ['TASK_LIST_COLLABORATOR', 'AGGREGATED_TASKS_SHARING_RECORDS'],

    [NewPermission.DASHBOARDS_VIEW]: ['DASHBOARDS_VIEW'],
    [Permission.DASHBOARDS_VIEW]: ['DASHBOARDS_VIEW_OLD', 'DASHBOARDS_SHARING_RECORDS', 'DASHBOARDS_VIEW'],
    [Permission.DASHBOARDS_CREATE_EDIT]: ['DASHBOARDS_CREATE_EDIT', 'DASHBOARDS_CREATE', 'DASHBOARDS_EDIT'],
    [Permission.DASHBOARDS_DELETE]: ['DASHBOARDS_DELETE_OLD'],
    [Permission.REPORTS_REPORT_BUILDER]: [
      'REPORTS_REPORT_BUILDER',
      'REPORT_BUILDER_CREATE',
      'REPORTS_BUILDER_VIEW',
      'REPORTS_BUILDER_EDIT',
      'REPORTS_BUILDER_DELETE',
      'REPORT_BUILDER_SHARING_RECORDS',
    ],
    [Permission.REPORTS_PDF]: [
      'REPORTS_PDF',
      'PDF_REPORTS_CREATE',
      'PDF_REPORTS_VIEW',
      'PDF_REPORTS_EDIT',
      'PDF_REPORTS_DELETE',
      'PDF_REPORTS_SHARING_RECORDS',
    ],

    // ------------ old-new permissions merged ---------------- //

    [Permission.PROJECT_PIPELINE_CHANGE_VISIBLE_COLUMNS]: ['PROJECT_PIPELINE_CHANGE_VISIBLE_COLUMNS'],
    [Permission.PROJECT_PIPELINE_ADD_USER]: ['PROJECT_PIPELINE_ADD_USER'],
    [Permission.PROJECT_SHARE]: ['PROJECT_SHARE', 'DEALS_SHARING_RECORDS'],
    [Permission.PIPELINE_EDIT_DYNAMIC_FILTER]: ['PIPELINE_EDIT_DYNAMIC_FILTER'],
    [Permission.PROJECT_ACCESS_ALL_PIPELINES]: ['PROJECT_ACCESS_ALL_PIPELINES'],
    [Permission.DETAILS_TAB]: ['DETAILS_TAB'],
    [Permission.TASKS_TAB]: ['TASKS_TAB'],
    [Permission.NOTES_TAB]: ['NOTES_TAB'],
    [Permission.INSIGHTS_TAB]: ['INSIGHTS_TAB'],
    [Permission.CONTACTS_TAB]: ['CONTACTS_TAB'],
    [Permission.CUSTOM_OBJECTS_TAB]: ['CUSTOM_OBJECTS_TAB'],
    [Permission.CONTROL_GROUP_BY_DROPDOWN]: ['CONTROL_GROUP_BY_DROPDOWN'],
    [Permission.TASK_LIST_ACCESS_ALL]: ['TASK_LIST_ACCESS_ALL'],
    [Permission.TASK_LIST_APPROVALS]: ['TASK_LIST_APPROVALS'],
    [Permission.TASK_LIST_CHANGE_VISIBLE_COLUMNS]: ['TASK_LIST_CHANGE_VISIBLE_COLUMNS'],
    [Permission.PROPERTY_FILES_TAB]: ['PROPERTY_FILES_TAB'],
    [Permission.PROPERTY_NOTES_TAB]: ['PROPERTY_NOTES_TAB'],
    [Permission.PROPERTY_LEASES_TAB]: ['PROPERTY_LEASES_TAB', 'LEASES_VIEW'],
    [Permission.PROPERTY_DEALS_TAB]: ['PROPERTY_DEALS_TAB', 'DEALS_VIEW'],
    [Permission.PROPERTY_NEARBY_TAB]: ['PROPERTY_NEARBY_TAB'],
    [Permission.PROPERTY_CUSTOM_OBJECTS_TAB]: ['PROPERTY_CUSTOM_OBJECTS_TAB'],
    [Permission.MAPS_ACCESS_ALL]: ['MAPS_ACCESS_ALL'],
    [Permission.MAPS_EDIT_LAYOUT]: ['MAPS_EDIT_LAYOUT'],

    [Permission.DEAL_TASK_SETTINGS]: ['DEAL_TASK_SETTINGS'],
    [Permission.PROPERTY_TASKS_TAB]: ['PROPERTY_TASKS_TAB'],
    [Permission.PRIVATE_PROPERTY_TASKS]: ['PRIVATE_PROPERTY_TASKS'],
    [Permission.PRIVATE_DEAL_TASKS]: ['PRIVATE_DEAL_TASKS'],
  });
}

export enum Module {
  DASHBOARDS = 'DASHBOARDS',
  DEALS = 'DEALS',
  CONTACTS = 'CONTACTS',
  TASKS = 'TASKS',
  GLOBAL_TASKS = 'GLOBAL_TASKS',
  AGGREGATED_TASKS = 'AGGREGATED_TASKS',
  PROPERTIES = 'PROPERTIES',
  MAPS = 'MAPS',
  REPORTS = 'REPORTS',
  SETTINGS = 'SETTINGS',
  LEASES = 'LEASES',
  DISPOSITIONS = 'DISPOSITIONS',
  LOAN_QUOTES = 'LOAN_QUOTES',
}

export enum ReservedRole {
  Admin = 'admin',
  Collaborator = 'collaborator',
  Limited = 'limited',
  Standard = 'standard',
}

export const ModulePermission = {
  [Module.GLOBAL_TASKS]: [Permission.TASK_LIST_GLOBAL],
  [Module.AGGREGATED_TASKS]: [Permission.TASK_LIST_AGGREGATE],
  [Module.DASHBOARDS]: [Permission.DASHBOARDS_CREATE_EDIT, Permission.DASHBOARDS_VIEW, Permission.DASHBOARDS_DELETE],
  [Module.LEASES]: [Permission.PROPERTY_LEASES_TAB],
  [Module.DISPOSITIONS]: [],
  [Module.LOAN_QUOTES]: [],
  [Module.DEALS]: [
    Permission.DEALS_ADD,
    Permission.PROJECT_PIPELINE_ADD_USER,
    Permission.PROJECT_ADD_USER,
    Permission.PROJECT_ACCESS_ALL_PIPELINES,
    Permission.PROJECT_ACCESS_ALL,
    Permission.PROJECT_SHARE,
    Permission.DETAILS_TAB,
    Permission.FILES_TAB,
    Permission.TASKS_TAB,
    Permission.PRIVATE_DEAL_TASKS,
    Permission.DEAL_TASK_SETTINGS,
    Permission.NOTES_TAB,
    Permission.INSIGHTS_TAB,
    Permission.CONTACTS_TAB,
    Permission.CUSTOM_OBJECTS_TAB,
    Permission.PIPELINE_DEAL_FORM_EDIT,
    Permission.PROJECT_DASHBOARD_EDIT_LAYOUT,
    Permission.PIPELINE_EDIT_DYNAMIC_FILTER,
    Permission.PIPELINE_MANAGE,
    Permission.PROJECT_PIPELINE_CHANGE_VISIBLE_COLUMNS,
    Permission.CONTROL_GROUP_BY_DROPDOWN,
    Permission.DEALS_DELETE,
  ],
  [Module.CONTACTS]: [
    Permission.CONTACTS_ACCESS_ALL,
    Permission.CONTACTS_ADD,
    Permission.CONTACTS_CONTROL_COLUMNS,
    Permission.CONTACTS_MERGE,
    Permission.CONTACTS_DELETE,
    Permission.CONTACT_FORM_EDIT,
    Permission.COMPANY_FORM_EDIT,
  ],
  [Module.TASKS]: [
    Permission.TASK_LIST_GLOBAL,
    Permission.TASK_LIST_ACCESS_ALL,
    Permission.TASK_GLOBAL_SETTINGS,
    Permission.TASK_LIST_AGGREGATE,
    Permission.TASK_LIST_VIEWS,
    Permission.TASK_LIST_ADD_USER,
    Permission.TASK_LIST_COLLABORATOR,
    Permission.TASK_LIST_APPROVALS,
    Permission.TASK_LIST_CHANGE_VISIBLE_COLUMNS,
    Permission.TASK_LIST_EDIT_LAYOUT,
  ],
  [Module.PROPERTIES]: [
    Permission.PROPERTY_CREATE,
    Permission.PROPERTY_DETAILS_TAB,
    Permission.PROPERTY_FILES_TAB,
    Permission.PROPERTY_TASKS_TAB,
    Permission.PRIVATE_PROPERTY_TASKS,
    Permission.PROPERTY_NOTES_TAB,
    Permission.PROPERTY_LEASES_TAB,
    Permission.PROPERTY_DEALS_TAB,
    Permission.PROPERTY_NEARBY_TAB,
    Permission.PROPERTY_CUSTOM_OBJECTS_TAB,
    Permission.PROPERTY_DASHBOARD_EDIT_LAYOUT,
    Permission.PROPERTY_DELETE,
    Permission.PROPERTY_FORM_EDIT,
  ],
  [Module.MAPS]: [Permission.MAPS_ACCESS_ALL, Permission.MAPS_EDIT_LAYOUT],
  [Module.REPORTS]: [Permission.REPORTS_PDF, Permission.REPORTS_REPORT_BUILDER],
  [Module.SETTINGS]: [
    Permission.SETTINGS_ACCESS,
    Permission.SETTINGS_USERS,
    Permission.SETTINGS_PERMISSIONS,
    Permission.SETTINGS_DROPDOWNS,
    Permission.SETTINGS_FIELDS,
    Permission.SETTINGS_TASK_TEMPLATES,
    Permission.SETTINGS_TASK_ROLES,
    Permission.SETTINGS_SHEETS,
    Permission.SETTINGS_APPS,
    Permission.SETTINGS_CODE,
    Permission.SETTINGS_IMPORTS,
    Permission.SETTINGS_OBJECT_MANAGER,
    Permission.SETTINGS_AUTOMATIONS,
  ],
};

export const ModuleNewPermission = {
  [Module.GLOBAL_TASKS]: [
    NewPermission.GLOBAL_TASKS_SHARING_RECORDS,
    NewPermission.GLOBAL_TASK_LISTS_DELETE,
    NewPermission.GLOBAL_TASK_LISTS_CREATE,
    NewPermission.GLOBAL_TASK_LISTS_EDIT,
    NewPermission.GLOBAL_TASK_LISTS_MANAGE_ADD_FORM,
    NewPermission.GLOBAL_TASK_LISTS_VIEW,
  ],
  [Module.AGGREGATED_TASKS]: [
    NewPermission.AGGREGATED_TASKS_PUBLIC_CUSTOM_VIEWS,
    NewPermission.AGGREGATED_TASK_LISTS_MANAGE_ADD_FORM,
    NewPermission.AGGREGATED_TASK_LISTS_EDIT,
    NewPermission.AGGREGATED_TASK_LISTS_DELETE,
    NewPermission.AGGREGATED_TASK_LISTS_CREATE,
    NewPermission.AGGREGATED_TASKS_SHARING_RECORDS,
    NewPermission.AGGREGATED_TASK_LISTS_VIEW,
  ],
  [Module.DASHBOARDS]: [
    NewPermission.DASHBOARDS_CREATE,
    NewPermission.DASHBOARDS_EDIT,
    NewPermission.DASHBOARDS_SHARING_RECORDS,
    NewPermission.DASHBOARDS_VIEW,
    NewPermission.DASHBOARDS_DELETE,
  ],
  [Module.DEALS]: [
    NewPermission.DEALS_DELETE,
    NewPermission.DEALS_CREATE,
    NewPermission.DEALS_VIEW,
    NewPermission.DEALS_EDIT,
    NewPermission.DEALS_PUBLIC_CUSTOM_VIEWS,
    NewPermission.DEALS_SHARING_RECORDS,
    NewPermission.DEALS_MANAGE_ADD_FORM,
    NewPermission.DEALS_MANAGE_LAYOUT,
  ],
  [Module.LOAN_QUOTES]: [
    NewPermission.LOAN_QUOTES_VIEW,
    NewPermission.LOAN_QUOTES_CREATE,
    NewPermission.LOAN_QUOTES_EDIT,
    NewPermission.LOAN_QUOTES_DELETE,
    NewPermission.LOAN_QUOTES_MANAGE_ADD_FORM,
    NewPermission.LOAN_QUOTES_MANAGE_LAYOUT,
    NewPermission.LOAN_QUOTES_PUBLIC_CUSTOM_VIEWS,
    NewPermission.LOAN_QUOTES_SHARING_RECORDS,
  ],
  [Module.LEASES]: [
    NewPermission.LEASES_VIEW,
    NewPermission.LEASES_CREATE,
    NewPermission.LEASES_EDIT,
    NewPermission.LEASES_DELETE,
    NewPermission.LEASES_MANAGE_ADD_FORM,
    NewPermission.LEASES_MANAGE_LAYOUT,
    NewPermission.LEASES_PUBLIC_CUSTOM_VIEWS,
    NewPermission.LEASES_SHARING_RECORDS,
  ],
  [Module.DISPOSITIONS]: [
    NewPermission.DISPOSITIONS_VIEW,
    NewPermission.DISPOSITIONS_CREATE,
    NewPermission.DISPOSITIONS_EDIT,
    NewPermission.DISPOSITIONS_DELETE,
    NewPermission.DISPOSITIONS_MANAGE_ADD_FORM,
    NewPermission.DISPOSITIONS_MANAGE_LAYOUT,
    NewPermission.DISPOSITIONS_PUBLIC_CUSTOM_VIEWS,
    NewPermission.DISPOSITIONS_SHARING_RECORDS,
  ],
  [Module.CONTACTS]: [
    NewPermission.CONTACTS_CREATE,
    NewPermission.CONTACTS_EDIT,
    NewPermission.CONTACTS_VIEW,
    NewPermission.CONTACTS_DELETE,
    NewPermission.CONTACTS_MANAGE_ADD_FORM,
    NewPermission.CONTACTS_MANAGE_LAYOUT,
    NewPermission.CONTACTS_SHARING_RECORDS,
    NewPermission.COMPANIES_VIEW,
    NewPermission.COMPANIES_CREATE,
    NewPermission.COMPANIES_EDIT,
    NewPermission.COMPANIES_DELETE,
    NewPermission.COMPANIES_MANAGE_ADD_FORM,
    NewPermission.COMPANIES_MANAGE_LAYOUT,
    NewPermission.COMPANIES_PUBLIC_CUSTOM_VIEWS,
    NewPermission.COMPANIES_SHARING_RECORDS,
  ],
  [Module.TASKS]: [
    Permission.TASK_LIST_GLOBAL,
    Permission.TASK_LIST_ACCESS_ALL,
    Permission.TASK_GLOBAL_SETTINGS,
    Permission.TASK_LIST_AGGREGATE,
    Permission.TASK_LIST_VIEWS,
    Permission.TASK_LIST_ADD_USER,
    Permission.TASK_LIST_COLLABORATOR,
    Permission.TASK_LIST_APPROVALS,
    Permission.TASK_LIST_CHANGE_VISIBLE_COLUMNS,
    Permission.TASK_LIST_EDIT_LAYOUT,
  ],
  [Module.PROPERTIES]: [
    NewPermission.PROPERTIES_VIEW,
    NewPermission.PROPERTIES_CREATE,
    NewPermission.PROPERTIES_EDIT,
    NewPermission.PROPERTIES_DELETE,
    NewPermission.PROPERTIES_MANAGE_ADD_FORM,
    NewPermission.PROPERTIES_MANAGE_LAYOUT,
    NewPermission.PROPERTIES_PUBLIC_CUSTOM_VIEWS,
    NewPermission.PROPERTIES_SHARING_RECORDS,
  ],
  [Module.MAPS]: [Permission.MAPS_ACCESS_ALL, Permission.MAPS_EDIT_LAYOUT],
  [Module.REPORTS]: [
    NewPermission.DASHBOARDS_CREATE,
    NewPermission.DASHBOARDS_EDIT,
    NewPermission.DASHBOARDS_DELETE,
    NewPermission.DASHBOARDS_VIEW,
    NewPermission.DASHBOARDS_SHARING_RECORDS,
    NewPermission.REPORT_BUILDER_CREATE,
    NewPermission.REPORTS_BUILDER_EDIT,
    NewPermission.REPORT_BUILDER_SHARING_RECORDS,
    NewPermission.REPORTS_BUILDER_VIEW,
    NewPermission.REPORTS_BUILDER_DELETE,
    NewPermission.PDF_REPORTS_CREATE,
    NewPermission.PDF_REPORTS_EDIT,
    NewPermission.PDF_REPORTS_DELETE,
    NewPermission.PDF_REPORTS_VIEW,
    NewPermission.PDF_REPORTS_SHARING_RECORDS,
  ],
  [Module.SETTINGS]: [
    NewPermission.SETTINGS_MANAGE_WORKSPACE,
    NewPermission.SETTINGS_MANAGE_USERS,
    NewPermission.SETTINGS_MANAGE_TEAMS,
    NewPermission.SETTINGS_MANAGE_PERMISSIONS,
    NewPermission.SETTINGS_MANAGE_ROLES,
    NewPermission.SETTINGS_MANAGE_DROPDOWNS,
    NewPermission.SETTINGS_MANAGE_OBJECTS_MANAGER,
    NewPermission.SETTINGS_MANAGE_FIELDS,
    NewPermission.SETTINGS_MANAGE_TASK_TEMPLATES,
    NewPermission.SETTINGS_MANAGE_SHEETS,
    NewPermission.SETTINGS_MANAGE_APPS,
    NewPermission.SETTINGS_MANAGE_CODE,
    NewPermission.SETTINGS_MANAGE_IMPORTS,
    NewPermission.SETTINGS_MANAGE_AUTOMATIONS,
    NewPermission.SETTINGS_MANAGE_EXPORTS,
    NewPermission.SETTINGS_MANAGE_ACCOUNT_HOOKS,
    NewPermission.SETTINGS_MANAGE_LAYOUTS,
  ],
};
