import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Team } from '@core/models';
import { switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { AccountState } from '@state/account/state';
import { PaginatedResource } from '@shared/models/paginated-resource';

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  private readonly httpClient = inject(HttpClient);
  private readonly accountId$ = inject(Store).select(AccountState.getAccountId);

  getTeams(page: number, per_page: number): Observable<PaginatedResource<Team>> {
    const params = new HttpParams().set('page', page).set('per_page', per_page);

    return this.accountId$.pipe(
      switchMap((accountId) => {
        return this.httpClient.get<PaginatedResource<Team>>(`accounts/${accountId}/teams`, { params });
      }),
    );
  }

  createTeam(team: Pick<Team, 'name' | 'parent_id'>): Observable<Team> {
    return this.accountId$.pipe(
      switchMap((accountId) => this.httpClient.post<Team>(`accounts/${accountId}/teams`, { team })),
    );
  }

  editTeam(team: Pick<Team, 'name' | 'parent_id'>, teamId: number): Observable<Team> {
    return this.accountId$.pipe(
      switchMap((accountId) => this.httpClient.put<Team>(`accounts/${accountId}/teams/${teamId}`, { team })),
    );
  }

  deleteTeam(id: number) {
    return this.accountId$.pipe(
      switchMap((accountId) => this.httpClient.delete<Team>(`accounts/${accountId}/teams/${id}`)),
    );
  }

  /**
   * We flatten Teams and add path information to display them on ag-grid's DataTree
   */
  public static flattenTeamsAndAddPath(teams: Team[], path = []): (Team & { path: string[] })[] {
    return (
      teams?.reduce((acc, team) => {
        const newPath = [...path, `${team.id}`];

        acc.push({
          ...team,
          path: newPath,
        });

        if (team?.children?.length > 0) {
          acc = acc.concat(TeamsService.flattenTeamsAndAddPath(team.children, newPath));
        }

        return acc;
      }, <(Team & { path: string[] })[]>[]) ?? []
    );
  }
}
