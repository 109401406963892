import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

import { DialogModel } from '../dialog.types';

/**
 * @deprecated This component is deprecated. Please use `ConfirmationDialogComponent` instead.
 */
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  constructor(
    private readonly matDialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogModel,
  ) {}

  delete(result = false): void {
    this.matDialogRef.close(result);
  }

  get maxWidth(): string {
    return this.dialogData?.maxWidth || '600px';
  }
}
